import React from 'react';

function Top(props) {

    return (
        <div className="md:flex text-[#D1D1D1] container md:space-y-0 space-y-6 px-2  mx-auto py-8 mb_font_to text-sm justify-center items-center">
            <div className="lg:w-1/5  md:w-1/4 w-full">Toolbrothers Powertools GmbH</div>
            <div className="lg:w-1/5  md:w-1/4 w-full">Robert Bosch Breite 9</div>
            <div className="lg:w-1/5  md:w-1/4 w-full">37083 Göttingen</div>
            <div className="lg:w-1/5  md:w-1/4 w-full">Telefon: 0551 / 996 909 88</div>
            <div className="flex lg:w-1/5 md:w-0 space-x-1.5 text-center justify-center justify-items-center items-center md:invisible lg:visible block">
                <div className="">
                    <a href="https://www.facebook.com/toolbrothers/" target="_blank" >
                        <img src="/icons/facebook.svg" className="rounded-sm" alt=""/>
                    </a>
                </div>
                <div className="">
                    <a href="https://www.instagram.com/toolbrothers_com/" target="_blank">
                        <img src="/icons/twitter.svg" className="rounded-sm" alt=""/>
                    </a>
                </div>
                <div className="">
                    <a href="https://www.youtube.com/toolbrothers" target="_blank">
                        <img src="/icons/youtube.svg" className="rounded-sm" alt=""/>
                    </a>
                </div>
                <div className="">
                    <a href="https://www.tiktok.com/@toolbrothers" className="">
                        <i className="fa-brands fa-tiktok p-2 bg-[#818a91] rounded-sm"></i>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Top;