import React from 'react';
import {Link} from "react-router-dom";

const ElementPopulated = () => {
    return (
        <>
            <div className="md:flex text-center justify-center justify-items-center items-center w-full text-white container w-full m-auto mb-font-makita mb-[7%]" >
                <div className="md:w-1/3 w-full m-auto">
                    <h1 className="lg:text-[40px] md:text-[25px] text-xl font-bold">
                        XGT® DF001G
                    </h1>
                    <h3 className="text-[23px] mb-10">Bohrschrauber</h3>
                    <Link to={`/schlagbohrschrauber/makita-xgt-df001g`} className="text-[#39888A] m-auto md:text-auto md:text-[17px] m-auto" rel="noreferrer">Zum Schrauber ></Link>
                    <img src="images/df001_new1.jpg" className="m-auto mt-[10%] elementor-animation-bounce-out elementor-widget-image" alt=""/>
                </div>
                <div className="md:w-1/3 w-full m-auto">
                    <img src="images/21177-Makita.jpg" className="m-auto mt-[10%] elementor-animation-bounce-out elementor-widget-image" alt=""/>
                    <Link to={`/category/schlagbohrschrauber`} className="text-[#39888A] m-auto md:text-auto md:text-[17px] m-auto" rel="noreferrer">Zum Schrauber ></Link>
                </div>
                <div className="md:w-1/3 w-full m-auto">
                    <h1 className="lg:text-[40px] md:text-[25px] text-xl font-bold">
                        XGT® HP001G
                    </h1>
                    <h3 className="text-[23px] mb-10">Schlagbohrschrauber</h3>
                    <Link to={`/schlagbohrschrauber/makita-xgt-hp001g`} className="text-[#39888A] m-auto md:text-auto md:text-[17px] m-auto" rel="noreferrer">Jetzt entdecken ></Link>
                    <img src="images/hp001_new.jpg " className="m-auto mt-[10%] elementor-animation-bounce-out elementor-widget-image" alt=""/>
                </div>
            </div>
            <div className="w-full">
                <img src="images/banner_image.png" className="mb-shadow-banner" alt=""/>
            </div>
        </>
        
    );
};

export default ElementPopulated;