import React from 'react';
import {Link} from "react-router-dom";
import Map from "../../Components/Content_components/Map";
import Footer from "../../Components/Footer";

const About = () => {
    return (
        <>
            <div className="md:px-0 px-3">
                <div className="text-white m-auto items-center justify-center container md:w-2/3">
                    <h1 className="xl:text-[52px] lg:text-[40px] md:text-[30px] text-2xl mt-[8%] font-semibold text-center">Über uns</h1>
                    <h3 className="xl:text-[27px] text-[20px] font-semibold text-center mt-[4%] mb-[6%]"><a href="https://toolbrothers.com/" className="text-[#39888A] font-semibold xl:text-[27px] text-[20px]" target="_blank">TOOLBROTHERS</a> – UNSER <span className="text-red-700">♥</span> SCHLÄGT FÜR ELEKTROWERKZEUGE!</h3>
                </div>

                <div className="md:w-3/5 text-white m-auto items-center justify-center container">
                    <p className=" leading-8">
                        Wer schonmal vor dem Kauf eines Werkzeugs stand, der weiß: das
                        <a href="https://toolbrothers.com/" className="text-[#39888A]" target="_blank"> passende Gerät </a>
                        zu finden, ist oftmals nicht viel einfacher, als das Heimwerken selbst!
                        Die Welt der <a href="https://toolbrothers.com/" className="text-[#39888A]" target="_blank">Elektrowerkzeuge</a> ist ein Dschungel voll von unzähligen Herstellern,
                        Gerätetypen, Produktvariationen und technischen Angaben. Sich mal ebend da durch
                        zu tasten? Fast unmöglich, insbesondere der Laie steht am Anfang vor einem großen
                        Berg voll von Informationen. Das Hauptproblem für Anfänger: Welche Art von Gerät
                        brauche ich überhaupt, um meine Arbeit zu vollbringen? Und selbst wenn man weiß,
                        was für eine Art Gerät man braucht, heißt das noch lange nicht, dass man weiß,
                        wie viel Leistung man für was braucht! Mal ebend Fließen schneiden? Ja, das kann
                        man durchaus mit einer Säge, einem Schleifer oder einem speziellen Fließenschneider
                        machen, doch was passt denn nun wirklich für meine Ansprüche?! Und das ist noch eines der kleineren Probleme!
                        Die gleichen technischen Daten können je nach Hersteller oftmals unterschiedliche
                        <Link to="/" className="text-[#39888A]"> Bezeichnungen </Link> tragen. Wie soll man denn da noch vernünftig vergleichen können?
                    </p>
                    <div className="mb-[6%]">
                        <h1 className="font-semibold py-10 text-xl">Keine Sorge! Wir leiten Sie gekonnt durch diese große und komplexe Welt.</h1>

                        <ul className="list-disc m-auto items-center justify-center px-10 leading-8">
                            <li>Sie wissen nicht, was für eine Art von Gerät Sie brauchen? Kein Problem!</li>
                            <li>Sie möchten unterschiedliche Geräte und Hersteller auf einen Blick miteinander vergleichen? Kein Problem!</li>
                            <li>Sie wollen den günstigsten Preis für das Gerät finden? Kein Problem!</li>
                            <li>Was tut sich neues in der Elektrowerkzeug-Welt? Wir informieren Sie!</li>
                            <li>Sie brauchen eine Produktberatung? Wir helfen Ihnen!</li>
                        </ul>
                    </div>
                </div>
                <Map />
            </div>
            <Footer bg="bg-black" />
        </>

    );
}
export default About;