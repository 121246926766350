import React from 'react';
import AnimatedElement from "../Components/AnimatedElement";
import LaunchBar from "../Components/LaunchBar";
import Footer from "../Components/Footer";

const Xgt = () => {
    return (
        <div className="">
            <div className="md:flex container w-full md:text-center justify-center items-center justify-items-center m-auto text-white py-[5%] px-3 relative">
                <AnimatedElement direction="right" classNames = "md:w-1/2 w-full m-auto text-left">
                    <img src="/images/makita.png" className="md:w-3/6 lg:w-2/6 xl:w-1/6 w-1/4" alt=""/>
                    <div>
                        <h1 className="md:text-[30px] lg:text-[40px] lg:text-[52px] text-3xl font-[700] md:my-5 my-2 m-auto">40V max XGT-Serie</h1>
                    </div>
                    <div className="">
                        <h3 className="md:text-[20px] lg:text-[30px] xl:text-[38px] text-md font-[400] m-auto">40 Volt Akku-Werkzeuge</h3>
                    </div>
                </AnimatedElement>
                <div className="md:w-1/2 w-full m-auto">
                    <img src="/images/21177-Makita.jpg" className="elementor-animation-bounce-out elementor-widget-image" alt=""/>
                </div>
            </div>

            <div className="bg-[#121317] md:w-full text-white justify-center justify-items-center items-center m-auto md:w-1/2 md:py-16 py-3 px-3">
                <div className="container justify-center justify-items-center items-center m-auto md:w-2/3">
                    <h1 className="md:text-[31px] text-xl text-[#A0A0A0] font-[700] md:mb-0 mb-5">Performance</h1>
                    <h1 className=" font-[600] md:my-5 my-5 md:text-[25px] lg:text-[40px] xl:text-[48px]">
                        Die Makita 40V max XGT-Serie - Für Anwendungen mit hohem Leistungsbedarf
                    </h1>
                    <p className="text-[#A0A0A0] leading-9">
                        Die 40V max XGT-Serie von Makita ergänzt die bekannte LXT-Produktpalette in einer Vielzahl
                        von Anwendungen, die hohe Leistung benötigen und ermöglicht somit eine noch nie
                        dagewesene Unabhängigkeit von Stromanschlüssen. Diese Leistungskraft kommt jetzt nämlich direkt aus den neuen 40V Akkus! <br/><br/>

                        Makita präsentiert ihre neue Technologie folgendermaßen: „Innovation zu schaffen,
                        heißt mehr als nur größere Akkus zu bauen. Es heißt, Akku-Technologien und moderne Antriebstechnik
                        so zu vereinen, dass Anwendern ein Maximum an Leistung, Zuverlässigkeit und Ergonomie zur Verfügung stehen.“ (<a href="http://makita-xgt.com/" target="_blank" className="text-[#39888A]  text-center m-auto" rel="noreferrer">makita-xgt.com</a>) <br/><br/>

                        Damit zeigt Makita, dass maximale Leistung in Verbindung mit bester Qualität und neuster Technologie ihr
                        Anspruch ist. Zu diesem Anspruch gehört auch ein optimaler Kundenservice, damit der Kunde am Ende ordentlich
                        Spaß mit seiner Maschine hat und vor allem effizient arbeiten kann.
                    </p>

                    <div className="text-center md:mt-10 mt-10">
                        <a href="https://www.makita.de/xgt.html" target="_blank" className="text-[#39888A]  text-center m-auto" rel="noreferrer">mehr erfahren ></a>
                    </div>
                </div>
            </div>

            <div className="items-center justify-center justify-items-center md:my-[5%] my-10 text-white m-auto container md:w-2/3 px-3">
                <h1 className="md:text-[27px] text-3xl text-[#A0A0A0] font-[400]">Inovation</h1>
                <h1 className="md:text-[30px] lg:text-[40px] xl:text-[47px] text-[35px] font-[600] md:my-5">
                    Erneuerungen
                </h1>
            </div>

            <div className="md:flex items-center justify-center justify-items-center m-auto my-[2%] md:w-1/2  text-white px-3 sp relative">
                <AnimatedElement classNames="md:w-1/2 w-full m-auto md:text-left text-center" direction="right">
                    <h1 className="md:text-[20px] lg:text-[40px] xl:text-[37px] text-2xl md:font-[600] md:my-5 ">
                        MEHR POWER
                    </h1>
                    <h1 className="md:text-[15px] text-xl text-[#A0A0A0] md:my-0 mt-3 mb-8 font-[400]">doppelt so viel wie die 18 V Akkus</h1>
                </AnimatedElement>
                <AnimatedElement classNames="md:w-1/2 w-full m-auto" direction="up">
                    <img src="/images/Bildschirmfoto-2020-08-21-um-13.07.14.jpg" alt="" className="m-auto elementor-animation-bounce-out elementor-widget-image"/>
                </AnimatedElement>
            </div>

            <div className="md:flex items-center justify-center justify-items-center m-auto my-[2%] md:w-1/2 text-white px-3 relative">
                <AnimatedElement classNames="md:w-1/2 w-full m-auto md:text-left text-center block md:hidden mt-10" direction="right">
                    <h1 className="md:text-[20px] lg:text-[40px] xl:text-[37px] text-2xl md:font-[600] md:my-5 ">
                        ROBUSTER
                    </h1>
                    <h1 className="md:text-[15px] text-xl text-[#A0A0A0] md:my-0 mt-3 mb-8 font-[400]">mehr Schutz und Sicherheit</h1>
                </AnimatedElement>
                <AnimatedElement classNames="md:w-1/2 w-full m-auto" direction="right">
                    <img src="/images/Bildschirmfoto-2020-08-21-um-13.09.17-759x1024.jpg" alt="" className="m-auto elementor-animation-bounce-out elementor-widget-image"/>
                </AnimatedElement>
                <AnimatedElement classNames="md:w-1/2 w-full m-auto text-center md:block hidden" direction="up">
                    <h1 className="md:text-[20px] lg:text-[40px] xl:text-[37px] text-md font-[600] md:my-5 m-auto">
                        ROBUSTER
                    </h1>
                    <h1 className="md:text-[15px] text-sm text-[#A0A0A0] font-[400] m-auto"> mehr Schutz und Sicherheit</h1>
                </AnimatedElement>
            </div>

            <div className="md:flex items-center justify-center justify-items-center m-auto my-[2%] md:w-1/2  text-white px-3 sp relative">
                <AnimatedElement classNames="md:w-1/2 w-full m-auto md:text-left text-center" direction="right">
                    <h1 className="md:text-[20px] lg:text-[40px] xl:text-[37px] text-2xl md:font-[600] md:my-5 ">
                        Digitale Kommunikation
                    </h1>
                    <h1 className="md:text-[15px] text-xl text-[#A0A0A0] md:my-0 mt-3 mb-8 font-[400]">doppelt so viel wie die 18 V Akkus</h1>
                </AnimatedElement>
                <AnimatedElement classNames="md:w-1/2 w-full m-auto" direction="up">
                    <img src="/images/Bildschirmfoto-2020-08-21-um-13.11.15.jpg" alt="" className="m-auto elementor-animation-bounce-out elementor-widget-image"/>
                    <div className="md:mt-10 mt-2 md:test-left text-center">
                        <a href="https://toolbrothers.com/elektrowerkzeug-de/schrauber-and-bohrschrauber/schlagbohrschrauber/akku-schlagbohrschrauber/makita-dhp482z-akku-schlagbohrschrauber-18v-62nm-ohne-akku-ohne-ladegerat/" target="_blank" className="text-[#39888A]  text-center m-auto" rel="noreferrer">mehr erfahren ></a>
                    </div>
                </AnimatedElement>
            </div>

            <div className="md:w-full text-white justify-center justify-items-center items-center m-auto md:w-1/2 md:py-16 px-3">
                <div className="container justify-center justify-items-center items-center m-auto md:w-2/3">
                    <h1 className="md:text-[20px] lg:text-[35px] xl:text-[40px] text-md font-[500] md:my-5 mt-16">
                        DAS AKKU-WERKZEUG DER ZUKUNFT: <strong>
                        MAKITA <span className="text-[#ca3231]">XGT</span> UND <span className="text-[#ca3231]">LTX</span>
                    </strong>
                    </h1>
                    <p className="text-[#A0A0A0] mt-10 leading-9">
                        Wichtig: Die neue XGT-Serie ersetzt die bewährte LXT-Serie nicht.
                        Sie ergänzt sie viel mehr mit Maschinen, die für leistungsintensivere
                        Anforderungen entwickelt wurden. Die XGT- und die LXT-Serie werden weiterhin wachsen und
                        mit neuen Maschinen und Technologien erweitert, um die Arbeit des Kunden mit beiden Serien gleichermaßen zu verbessern und erleichtern.
                        Um eine Kompatibilität der Akkus beider Serien zu gewährleisten, hat
                        Makita einen Ladeadapter entwickelt. Dieser ermöglicht das Laden von LXT-Akkus auch mit den neuen 40V XGT-Ladegeräten.

                    </p>

                    <div className="text-center md:mt-10 mt-10">
                        <a href="https://www.makita.de/xgt.html" target="_blank" className="text-[#39888A]  text-center m-auto" rel="noreferrer">mehr erfahren ></a>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 px-3 lg:grid-cols-4 gap-4 w-full md:bg-[#121317] md:space-x-6 md:space-y-0 space-y-4 justify-center justify-items-center items-center text-center text-white m-auto mt-10 py-10 container m-auto w-full">
                <div className="w-full m-auto md:p-6 py-5">
                    <span>
                        <div className="h-[76px] w-[76px] hover:bg-[#ca3231] bg-black text-center justify-center items-center justify-items-center rounded-full m-auto text-4xl fe_animate-bob">
                            <i aria-hidden="true" className="fas fa-battery-full m-auto relative text-center mt-5"></i>
                        </div>
                        <h1 className="font-bold md:text-[22px] text-sm my-8 m-auto">
                            LEISTUNGSSTARK
                        </h1>
                    </span>
                    <span className="m-auto md:text-auto text-[10px] md:text-[17px] m-auto">
                        für Anwendungen mit besonders hohem Leistungsbedarf
                    </span>
                </div>
                <div className="w-full m-auto md:p-6 py-5">
                    <span>
                        <div className="h-[76px] w-[76px] hover:bg-[#ca3231] bg-black text-center justify-center items-center justify-items-center rounded-full m-auto text-4xl fe_animate-bob">
                            <i aria-hidden="true" className="fas fa-globe-americas m-auto relative text-center mt-5 "></i>
                        </div>
                        <h1 className="font-bold md:text-[22px] text-sm my-8 m-auto">
                            ZUKUNFTSSICHER
                        </h1>
                    </span>
                    <span className="m-auto md:text-auto text-[10px] md:text-[17px] m-auto">
                        laufende Weiterentwicklung der XGT- und der LXT-Serie ​
                    </span>
                </div>
                <div className="w-full m-auto md:p-6 py-5">
                    <span>
                        <div className="h-[76px] w-[76px] hover:bg-[#ca3231] bg-black text-center justify-center items-center justify-items-center rounded-full m-auto text-4xl fe_animate-bob">
                            <i aria-hidden="true" className="fas fa-lightbulb m-auto relative text-center mt-5"></i>
                        </div>
                        <h1 className="font-bold md:text-[22px] text-sm my-8 m-auto">
                            INTELLIGENT
                        </h1>
                    </span>
                    <span className="m-auto md:text-auto text-[10px] md:text-[17px] m-auto">
                        digitale Kommunikation zwischen Werkzeug und Akku
                    </span>
                </div>
                <div className="w-full m-auto md:p-6 py-5">
                    <span>
                        <div className="h-[76px] w-[76px] hover:bg-[#ca3231] bg-black text-center justify-center items-center justify-items-center rounded-full m-auto text-4xl fe_animate-bob">
                            <i aria-hidden="true" className="fas fa-hands-helping m-auto relative text-center mt-5"></i>
                        </div>
                        <h1 className="font-bold  md:text-[22px] text-sm my-8 m-auto">
                            ZUVERLÄSSIG
                        </h1>
                    </span>
                    <span className="m-auto md:text-auto text-[10px] md:text-[17px] m-auto">
                        hohe Stossfestigkeit dank integrierter Dämpfer
                    </span>
                </div>
            </div>

            <div className="bg-[#121317] md:w-full text-white justify-center justify-items-center items-center m-auto md:w-1/2  p-[5%] mt-[6%]">
                <div className="md:flex items-center bg-black container justify-center justify-items-center m-auto my-[2%] md:w-5/6 lg:w-4/5 xl:w-3/5 text-white p-[8%] md:p-[3%] w-full">
                    <div className="md:w-1/2 w-full m-auto">
                        <h1 className="md:text-[18px] text-2xl text-[#A0A0A0] font-[400]">Von 0 auf 100 %</h1>
                        <h1 className="md:text-[20px] lg:text-[35px] xl:text-[40px] text-3xl font-[600] md:my-5 my-7">
                            Voller Akku unter einer Stunde
                        </h1>
                        <div className="w-full rounded-full h-1.5 bg-black mt-7">
                            <LaunchBar level="90" />
                        </div>
                        <div className="text text-[#a0a0a0]">
                            >1 Stunde
                        </div>
                    </div>
                    <div className="md:w-1/2 w-full m-auto text-center">
                        <img src="images/Bildschirmfoto-2020-08-21-um-13.21.53.jpg" alt="" className="m-auto elementor-animation-bounce-out elementor-widget-image"/>
                    </div>
                </div>
            </div>

            <div className="md:flex container w-full md:w-3/5 justify-center items-center justify-items-center m-auto text-white px-3 md:px-0 py-[5%] relative">
                <AnimatedElement classNames="md:w-1/2 w-full m-auto justify-center items-center justify-items-center m-auto" direction="right">
                    <div>
                        <h1 className="md:text-[20px] lg:text-[25px] xl:text-[27px] text-3xl text-[#A0A0A0] font-[400]">Einfache Ergänzung</h1>
                        <h1 className="md:text-[35px] lg:text-[40px] xl:text-[52px] text-3xl font-[700] md:my-5 my-2 m-auto">Ladeadapter</h1>
                    </div>
                    <div className="">
                        <p className="text-[#A0A0A0] mt-10 leading-9">
                            Mit Hilfe des Ladeadapter ADP10 lassen sich auch 18 V LXT-Akkus mit dem Ladegerät der neuen 40 V max. XGT-Serie laden.
                        </p>
                    </div>
                </AnimatedElement>
                <div className="md:w-1/2 w-full m-auto">
                    <img src="images/charging_adapter-552x1024.jpg" className="md:w-3/5 w-3/5 m-auto elementor-animation-bounce-out elementor-widget-image" alt=""/>
                </div>
            </div>

            <div className="items-center justify-center justify-items-center text-white m-auto container md:w-2/3 px-3 md:px-0">
                <h1 className="md:text-[20px] lg:text-[25px] xl:text-[27px] text-xl text-[#A0A0A0] font-[400]">XGT®-Serie</h1>
                <h1 className="mmd:text-[35px] lg:text-[40px] xl:text-[52px] text-[35px] font-[600] md:my-2">
                    Produkte
                </h1>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 items-center justify-center justify-items-center md:my-[5%] my-10 text-white m-auto container text-center md:w-2/3 ">
                <div className="w-full gap-4 m-auto text-center">
                    <a href="https://www.makita.de/jr001g.html"><img src="images/jr001_new.png" className="w-2/3 m-auto hover:scale-110 transition-all duration-500" alt=""/></a>
                    <h1 className="md:text-[21px] text-[25px] font-[600] mt-5">JR001G</h1>
                    <h1 className="md:text-[21px] text-md font-[400]">Reciprosäge XGT®</h1>
                </div>
                <div className="w-full m-auto text-center">
                    <a href="https://www.makita.de/td001g.html"><img src="images/td001_new.png" className="w-2/3 m-auto hover:scale-110 transition-all duration-500" alt=""/></a>
                    <h1 className="md:text-[21px] text-[25px] font-[600] mt-5">TD001G</h1>
                    <h1 className="md:text-[21px] text-md font-[400]">Schlagschrauber 1/4" XGT®</h1>
                </div>
                <div className="w-full m-auto text-center">
                    <a href="https://www.makita.de/df001g.html"><img src="images/df001_new1.png" className="w-2/3 m-auto hover:scale-110 transition-all duration-500" alt=""/></a>
                    <h1 className="md:text-[21px] text-[25px] font-[600] mt-5">DF001G</h1>
                    <h1 className="md:text-[21px] text-md font-[400]">Bohrschrauber XGT®</h1>
                </div>
                <div className="w-full m-auto text-center">
                    <a href="https://www.makita.de/hp001g.html"><img src="images/hp001_new.png" className="w-2/3 m-auto hover:scale-110 transition-all duration-500" alt=""/></a>
                    <h1 className="md:text-[21px] text-[25px] font-[600] mt-5">HP001G</h1>
                    <h1 className="md:text-[21px] text-md font-[400]">Schlagbohrschrauber XGT®</h1>
                </div>
                <div className="w-full  m-auto text-center">
                    <a href="https://www.makita.de/ga005g.html"><img src="images/ga005_new.png" className="w-2/3 m-auto hover:scale-110 transition-all duration-500" alt=""/></a>
                    <h1 className="md:text-[21px] text-[25px] font-[600] mt-5">GA005G</h1>
                    <h1 className="md:text-[21px] text-md font-[400]">Winkelschleifer XGT®</h1>
                </div>
                <div className="w-full m-auto text-center">
                    <a href="https://www.makita.de/ga013g.html"><img src="images/ga013_new1.png" className="w-2/3 m-auto hover:scale-110 transition-all duration-500" alt=""/></a>
                    <h1 className="md:text-[21px] text-[25px] font-[600] mt-5">GA013G</h1>
                    <h1 className="md:text-[21px] text-md font-[400]">Winkelschleifer XGT®</h1>
                </div>
                <div className="w-full m-auto text-center">
                    <a href="https://www.makita.de/hr003g.html"><img src="images/hr003_new.png" className="w-2/3 m-auto hover:scale-110 transition-all duration-500" alt=""/></a>
                    <h1 className="md:text-[21px] text-[25px] font-[600] mt-5">HR003G</h1>
                    <h1 className="md:text-[21px] text-md font-[400]">Bohrhammer XGT®</h1>
                </div>
                <div className="w-full m-auto text-center">
                    <a href="https://www.makita.de/hr004g.html"><img src="images/hr004_new.png" className="w-2/3 m-auto hover:scale-110 transition-all duration-500" alt=""/></a>
                    <h1 className="md:text-[21px] text-[25px] font-[600] mt-5">HR004G</h1>
                    <h1 className="md:text-[21px] text-md font-[400]">Bohrhammer XGT®</h1>
                </div>
            </div>
            <Footer bg="bg-[#0f0f0f]" />
        </div>

    );
};

export default Xgt;