import React from 'react';
import Map from "../../Components/Content_components/Map";
import Footer from "../../Components/Footer";

const Disclaimers = () => {
    return (
        <>
            <div className="md:px-0 px-3">
                <div className="text-white m-auto items-center justify-center container md:w-2/3 mb-[3%]">
                    <h1 className="xl:text-[52px] lg:text-[40px] md:text-[30px] text-2xl mt-[8%] font-semibold text-center">Impressum</h1>
                </div>
                <div className="mb-[2%] md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="font-semibold text-xl mt-[3%] md:text-2xl text-xl">Toolbrothers Powertools GMBH</h1>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-5 leading-8">
                    Straße: Robert-Bosch-Breite 9
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-15 leading-8">
                    <strong>Ort</strong>: 37079, Göttingen
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-5 leading-8">
                    <strong>Vertreten durch</strong>: Rauf Aliyev
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-5 leading-8">
                    <strong>Öffnungszeiten</strong>: Montags bis Freitag von 8:00 Uhr bis 16:00 Uhr
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-5 leading-8">
                    <strong>Telefon</strong>: 0551 / 996 909 88
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-5 leading-8">
                    <strong>E-Mail</strong>: <a href="mail:service@toolbrothers.eu" className="text-[#39888A]" target="_blank"> service@toolbrothers.eu </a>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-5 leading-8">
                    <strong>UmSt-ID</strong>: DE326192653
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-5 leading-8">
                    <strong>Eintragung im Handelsregister: Registergericht Amtsgericht Göttingen
                        Registernummer HRB</strong>: 205789
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-5 leading-8">
                    Die Europäische Kommission stellt eine Plattform zur Online- Streitbeilegung (OS) bereit, die Sie unter <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" className="text-[#39888A]" target="_blank"> http://ec.europa.eu/consumers/odr/ </a> finden.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-5 leading-8 mb-[5%]">
                    Wir sind nicht bereit und verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                </div>
                <Map />
            </div>
            <Footer bg="bg-black" />
        </>

    );
};

export default Disclaimers;