import React from 'react';
import {Link} from "react-router-dom";

const ScrewdriverCompare = () => {
    return (
        <div className="text-center text-white justify-center justify-items-center items-center mt-[5%] mb-[5%] ">
            <Link to="/category/bohrschrauber" className="md:text-[25px] lg:text-[36px] text-[25px] font-bold md:px-0 px-5">
                Welcher Akkuschrauber ist der  richtige für dich ?
            </Link>
            <div className="mt-10">
                <a href="https://toolbrothers.com/" className="text-[#39888A] m-auto md:text-auto md:text-[17px] m-auto" target="_blank" rel="noreferrer">Alle Makita Akkuschrauber vergleichen ></a>
            </div>

            <div className="md:flex w-full lg:w-2/3 m-auto container text-center justify-center items-center mt-[7%] md:space-x-4 md:space-y-0 space-y-4">
                <div className="md:w-1/3 w-full">
                    <img src="../images/DDF-485-1.png" className="m-auto " alt=""/>
                    <h1 className="mt-10">
                        <Link to="/category/bohrschrauber" className="m-auto md:text-auto lg:text-[22px] md:text-[18px]" rel="noreferrer">Makita DDF Bohrschrauber</Link>
                    </h1>
                    <p className="mt-8 text-[#7a7a7a] lg:text-[15px] text-sm md:px-0 px-10">
                        Das 2-Gang-Vollmetall-Planetengetriebe und die regulierbare Drehzahl sorgen für optimale Ergebnisse.
                    </p>
                    <h1 className="mt-16">
                        <Link to="/category/bohrschrauber" className="text-[#39888A] m-auto md:text-auto lg:text-[17px] m-auto" rel="noreferrer">zum Bohrschrauber ></Link>
                    </h1>
                </div>
                <div className="md:w-1/3 w-full">
                    <img src="../images/DDF-481-1024x684.png" className="m-auto" alt=""/>
                    <h1 className="mt-10">
                        <Link to="/category/bohrschrauber" className="m-auto md:text-auto lg:text-[22px] md:text-[18px]" rel="noreferrer">Makita DHP Schlagbohrschrauber</Link>
                    </h1>
                    <p className="mt-8 text-[#7a7a7a] lg:text-[15px] text-sm md:px-0 px-10">
                        Die leuchtstarke Doppel-LED mit Nachglimmfunktion sorgt für optimale Sichtverhältnisse beim Arbeiten.
                    </p>
                    <h1 className="mt-16">
                        <Link to="/category/schlagbohrschrauber" className="text-[#39888A] m-auto md:text-auto lg:text-[17px] m-auto" rel="noreferrer">zum Schlagbohrschrauber ></Link>
                    </h1>
                </div>
                <div className="md:w-1/4 w-full">
                    <img src="../images/df001_new1.jpg" className="m-auto -mt-5" alt=""/>
                    <h1 className="mt-10">
                        <Link to="/category/bohrschrauber" className="m-auto md:text-auto lg:text-[22px] md:text-[18px] -mt-5" rel="noreferrer">Makita XGT Akkuschrauber</Link>
                    </h1>
                    <p className="my-8 text-[#7a7a7a] lg:text-[15px] text-sm md:px-0 px-10 ">
                        Die neue 40V max XGT-Serie sieht zwar den 18V LXT-Akkus ziemlich ähnlich, jedoch ist sie einzigartig neu.
                    </p>
                    <h1 className="mt-[20px] ">
                        <Link to="/makita-40-volt-max-xgt-serie" className="text-[#39888A] m-auto md:text-auto lg:text-[17px] m-auto" rel="noreferrer">zum XGT Akkuschrauber ></Link>
                    </h1>
                </div>
            </div>
        </div>
    );
};

export default ScrewdriverCompare;