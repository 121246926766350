import React from 'react';
import AnimatedElement from "../AnimatedElement";

const ToolbrothersUser = (props) => {
    const {isContactInformation, isBesonders} = props
    return (
        <div className={`${isBesonders === "yes"? "bg-black mt-[2%] " : "bg-[#0f0f0f] md:p-10 mt-[7%] "} text-white mb-font-makita text-center justify-center justify-items-center items-center m-auto  md:w-full`}>
            <AnimatedElement classNames={`${isBesonders === "yes" ? " bg-[#0f0f0f]" : "bg-black"} m-auto container md:p-16 py-5 px-3 md:4/5`} direction="right">
                {
                    isBesonders === "yes"?
                        <>
                            <h1 className="font-bold lg:text-[40px] md:text-[25px] text-xl text-[20px]">
                                Das macht <a href="https://toolbrothers.com/" className="text-[#39888A]" target="_blank">TOOLBROTHERS</a>  so besonders
                            </h1>
                            <h1 className="font-bold md:text-[22px] mt-[5%]">
                                <a href="https://toolbrothers.com/" className="text-[#39888A]" target="_blank">TOOLBROTHERS</a> – UNSER  <span className="text-red-700">♥</span> SCHLÄGT FÜR POWER-TOOLS!
                            </h1>
                        </>
                        :
                        <h1 className="font-bold lg:text-[40px] md:text-[25px] text-xl text-[15px]">
                            TOOLBROTHERS – UNSER  <span className="text-red-700">♥</span> SCHLÄGT FÜR POWER-TOOLS!
                        </h1>
                }
                <p className="mt-16 ">
                    Wir möchten Ihnen helfen, sich in der riesigen Welt der Werkzeuge zurecht zu finden! Was für ein Gerät brauche ich?
                    Wie schlägt sich diese Maschine im Vergleich zu dieser? Wo finde ich den besten Preis? Das
                    sind die Hauptfragen, auf die Sie bei uns die richtigen Antworten finden.
                </p>
                <p className="mt-16">Werden Sie Teil unserer Community!</p>
            </AnimatedElement>
            <div className="container md:flex m-auto mt-10 md:space-x-10 md:space-y-0 space-y-10">
                <div className={`md:w-1/3 w-full m-auto md:p-2 md:px-0 px-5  ${isBesonders === "yes"? "bg-[#0f0f0f]" : "bg-black"} `}>
                    <img src="../images/scott-graham.jpg" className="transition-all duration-300 cursor-pointer filter grayscale hover:grayscale-0" alt=""/>
                    <h1 className="lg:text-[35px] md:text-[20px] text-xl my-4">
                        Kompetenter Partner
                    </h1>
                    <p className="lg:text-[16px]">
                        Ein Fokus unseres Unternehmens liegt in der Belieferung von Businesskunden
                        aller Größen. Unser mehr als 30-köpfiges, ständig wachsendes Team unterstützt unsere Firmenkunden in allen Fragen.
                    </p>
                </div>
                <div className={`md:w-1/3 w-full m-auto md:p-2 md:px-0 px-5  ${isBesonders === "yes"? "bg-[#0f0f0f]" : "bg-black"} `}>
                    <img src="../images/marissa.jpg " className="transition-all duration-300 cursor-pointer filter grayscale hover:grayscale-0" alt=""/>
                    <h1 className="lg:text-[35px] md:text-[20px] my-4">
                        Geschulte Mitarbeiter
                    </h1>
                    <p className="lg:text-[16px]">
                        Der hohe Qualitätsstandard unserer Firma wird sowohl durch
                        regelmäßig durchgeführte interne Schulungen wie auch durch
                        Zertifizierungskurse der einzelnen Hersteller gewährleistet.
                    </p>
                </div>
                <div className={`md:w-1/3 w-full m-auto md:p-2 md:px-0 px-5  ${isBesonders === "yes"? "bg-[#0f0f0f]" : "bg-black"} `}>
                    <img src="../images/petr-machacek.jpg " className="transition-all duration-300 cursor-pointer filter grayscale hover:grayscale-0" alt=""/>
                    <h1 className="lg:text-[34px] md:text-[20px] my-4">
                        Schneller Support
                    </h1>
                    <p className="lg:text-[16px]">
                        Wir beraten Sie umfassend bei allen Fragen zu den von uns
                        angebotenen Produkten. Ob vor oder nach dem Kauf, unsere
                        qualifizierten Servicemitarbeiter stehen Ihnen gerne zur Verfügung.
                    </p>
                </div>
            </div>
            {isContactInformation === "yes" ?
                <div className="md:flex w-full md:space-x-6 md:space-y-0 space-y-4 justify-center justify-items-center items-center text-center text-white m-auto mt-10 container m-auto w-full px-5">
                    <div className="w-full md:w-1/3 bg-black m-auto p-6">
                        <a href="mailto:service@toolbrothers.de">
                            <img src="images/mail.png" className="h-20 m-auto hover:animate-bounce transition-all duration-500" alt=""/>
                            <h1 className="font-bold lg:text-2xl md:text-xl text-lg mt-5 m-auto mb-3">
                                E-MAIL
                            </h1>
                        </a>
                        <a href="https://makita-akkuschrauber.de/category/bohrschrauber/" className="text-[#39888A] m-auto md:text-auto text-[14px] md:text-[17px] m-auto">service@toolbrothers.de</a>
                    </div>
                    <div className="w-full md:w-1/3 bg-black m-auto p-6">
                        <a href="tel:055199690996">
                            <img src="images/call.png" className="h-20 m-auto hover:animate-bounce transition-all duration-500" alt=""/>
                            <h1 className="font-bold lg:text-2xl md:text-xl text-lg mt-5 mt-5 m-auto mb-3">
                                TELEFON
                            </h1>
                        </a>
                        <a href="https://makita-akkuschrauber.de/category/bohrschrauber/" className="text-[#39888A] m-auto md:text-auto text-[14px] md:text-[17px] m-auto">0551 / 99690988</a>
                    </div>
                    <div className="w-full md:w-1/3 bg-black m-auto p-6">
                        <a href="https://www.google.com/maps/place/Robert-Bosch-Breite+9,+37079+G%C3%B6ttingen/@51.5477232,9.8892693,17z/data=!4m5!3m4!1s0x47bb2b3c6bcbd677:0xdea333e31d1f749!8m2!3d51.5477764!4d9.8916576?shorturl=1">
                            <img src="images/localization.png" className="h-20 m-auto hover:animate-bounce transition-all duration-500" alt=""/>
                            <h1 className="font-bold lg:text-2xl md:text-xl text-lg mt-5 mt-5 m-auto mb-3">
                                ADDRESSE
                            </h1>
                        </a>
                        <a href="https://makita-akkuschrauber.de/category/bohrschrauber/" className="text-[#39888A] m-auto md:text-auto text-[14px] md:text-[17px] m-auto">Robert-Bosch-Breite 9, 37079 Göttingen</a>
                    </div>
                </div>
                :
                null
            }
        </div>
    );
};

export default ToolbrothersUser;