import './App.css';
import Header from './Components/Header';
import Content from "./Components/Content";
import Footer from "./Components/Footer";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import MakitaDdf from "./Views/MakitaDdf";
import Makita_max from "./Views/Makita_max";
import View_product from "./Views/View_product";
import Xgt from "./Views/Xgt";
import {useState} from "react";
import About from "./Views/Footer_link/About";
import Contact from "./Views/Footer_link/Contact";
import Agb from "./Views/Footer_link/Agb";
import Data_protection from "./Views/Footer_link/Data_protection";
import Disclaimers from "./Views/Footer_link/Disclaimers";
import Ddf_vs_dhp from "./Views/Ddf_vs_dhp";
import Ddf from "./Views/Categoies/Ddf";
import Dhp from "./Views/Categoies/Dhp";

function App() {
    const [endpoint, setEndPoint]  = useState(window.location.pathname)


  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/' element={<Content/>}/>
        <Route path='/makita-ddf-bohrschrauber' element={<MakitaDdf/>}/>
        <Route path='/makita-40-volt-max' element={<Makita_max />}/>
        <Route path='/schlagbohrschrauber/:endpoint' element={<View_product />}/>
        <Route path='/bohrschrauber/:endpoint' element={<View_product />}/>
        <Route path='/makita-40-volt-max-xgt-serie' element={<Xgt />}/>
        <Route path='/uber-uns' element={<About />}/>
        <Route path='/kontakt' element={<Contact />}/>
        <Route path='/agb' element={<Agb />}/>
        <Route path='/datenschutz' element={<Data_protection />}/>
        <Route path='/uber-uns/impressum-2' element={<Disclaimers />}/>
        <Route path='/unterschied-ddf-und-dhp' element={<Ddf_vs_dhp />}/>
        <Route path='/category/bohrschrauber' element={<Ddf />}/>
        <Route path='/category/schlagbohrschrauber' element={<Dhp />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
