import React, {useEffect} from 'react';
import {Carousel} from "flowbite-react";
import ProductThreeD from "../Components/ThreeD/ProductThreeD";
import ToolbrothersUser from "../Components/Content_components/Toolbrothers_user";
import {Link} from 'react-router-dom'
import Makita from "../Components/Makita";
import Footer from "../Components/Footer";
const Makita_max = () => {
    const productsCarousel = [
        {endpoint: "makita-dhp-453", image_url: "images/DHP453_Banner.gif"},
        {endpoint: "makita-dhp-458", image_url: "images/DHP458_Banner.gif"},
        {endpoint: "makita-dhp-481", image_url: "images/DHP481_Banner.gif"},
        {endpoint: "makita-dhp-484", image_url: "images/DHP484_Banner.gif"},
        {endpoint: "makita-dhp-485", image_url: "images/DHP485_Banner-1.gif"},
        {endpoint: "makita-dhp-456", image_url: "images/DHP456_Banner.gif"},
    ]
    return (
        <>
            <div  className="h-36 sm:h-[20rem] xl:h-[30rem] 2xl:h-[40rem]">
                <Carousel indicators={false} className="top-0 bottom-0">
                    {
                        productsCarousel.map((items, index) => {
                            return (
                                <Link key={index} to={`/schlagbohrschrauber/${items.endpoint}`}><img src={items.image_url} className="top-0" alt="..."/></Link>
                            )
                        })
                    }
                </Carousel>
            </div>
            <div className="md:flex container w-full justify-center justify-items-center items-center m-auto">
                <ProductThreeD title="Makita DHP 456" link="//orbitvu.co/share/k9PkTiLqoZ4xt2pUegL7W7/144569/orbittour/script" id="orbittour-k9PkTiLqoZ4xt2pUegL7W7" />
                <ProductThreeD title="Makita DHP 458" link="//orbitvu.co/share/d5m2cUECLR7nsD7W9uZ8fS/144569/orbittour/script" id="orbittour-d5m2cUECLR7nsD7W9uZ8fS" />
            </div>
            <div className="md:flex container w-full justify-center justify-items-center items-center m-auto">
                <ProductThreeD title="Makita DHP 481" link="//orbitvu.co/share/6upuetu3LrXYCEVLqMMY8E/144569/orbittour/script" id="orbittour-6upuetu3LrXYCEVLqMMY8E" />
                <ProductThreeD title="Makita DHP 482" link="//orbitvu.co/share/GSgzaPXHq79g3StxJW6zPC/144569/orbittour/script" id="orbittour-GSgzaPXHq79g3StxJW6zPC" />
            </div>
            <div className="md:flex container w-full justify-center justify-items-center items-center m-auto">
                <ProductThreeD title="Makita DHP 484" link="//orbitvu.co/share/NFUm6ZMeKnk5QnR4uer7f4/144569/orbittour/script" id="orbittour-NFUm6ZMeKnk5QnR4uer7f4" />
                <ProductThreeD title="Makita DHP 485" link="//orbitvu.co/share/NuFyvVSRvAqryAgT4FNpeg/144569/orbittour/script" id="orbittour-NuFyvVSRvAqryAgT4FNpeg" />
            </div>

            <div className="container items-center flex justify-center m-auto lg:my-[200px] my-[100px]">
                <div className="providers-container w-full flex justify-between items-center md:w-1/2 md:px-0 px-10">
                    <img src="/images/makita-1024x228.png" className="h-auto md:w-[35%] w-[40%] md:w-1/3 w-1/2" alt=""/>
                    <img src="/images/lxt_l_500x196_wd_custom.png" className="h-auto md:w-[35%] w-[40%] md:w-1/3 w-1/2" alt=""/>
                </div>
            </div>

            <div className="md:flex w-full mt-[8%] m-auto justify-center justify-items-center items-center text-center container lg:w-1/2 md:w-4/5">
                <div className="text-white md:w-1/3 w-full m-auto">
                    <h1 className="lg:text-[37px] md:text-[25px] font-[700]">XPT</h1>
                    <p className="text-[#A0A0A0] leading-loose text-sm md:px-0 px-10">eXtreme Protection Technology schützt die Maschine vor Staub und Spritzwasser</p>
                </div>
                <div className="text-white md:w-1/3 w-full m-auto">
                    <h1 className="lg:text-[37px] md:text-[25px] font-[700] md:py-0 mt-5">2-Gang-Getriebe</h1>
                    <p className=" text-[#A0A0A0] leading-loose text-sm md:px-0 px-10"> 2-Gang-Planetengetriebe mit Metallzahnrädern</p>
                </div>
                <div className="text-white md:w-1/3 w-full m-auto">
                    <h1 className="lg:text-[37px] md:text-[25px] font-[700] md:py-0 mt-5">LED</h1>
                    <p className="text-[#A0A0A0] leading-loose text-sm md:px-0 px-10">integriertes LED-Licht zum Ausleuchten der Arbeitsstelle</p>
                </div>
            </div>

            <Makita/>

            <ToolbrothersUser isBesonders="yes"/>
            <Footer bg="bg-[#0f0f0f]" />
        </>
    );
};

export default Makita_max;