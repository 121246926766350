import {useEffect, useState} from "react";

const LaunchBar = (props) => {

    const {level} = props;

    function launchLevelBars() {
        let bars = document.querySelectorAll('.load-bar');

        bars.forEach(bar => {
            if (bar.getBoundingClientRect().top <= 9 * window.innerHeight / 10 && !bar.classList.contains('active')) {
                bar.classList.add("active");
            }
        })
    }

    useEffect(() => {
        launchLevelBars()
        window.addEventListener('scroll', launchLevelBars);
        window.addEventListener('load', launchLevelBars);

        return () => {
            window.removeEventListener('load', launchLevelBars);
            window.removeEventListener('scroll', launchLevelBars);
        }

    }, []);

    return (
        <>
            <div style={{'width': `${level}%`}}>
                <div className="bg-[#2DA1A4] h-1.5 rounded-full load-bar"></div>
            </div>
        </>
    );
}

export default LaunchBar;