import React from 'react';
import ProductThreeD from "../ThreeD/ProductThreeD";

const ThreeD = () => {
    return (
        <div className="mt-[5%] m-auto text-white text-center justify-center items-center mb-[10%] md:w-3/4 w-full m-auto">
            <ProductThreeD title="Makita DHP 482" link="//orbitvu.co/share/GSgzaPXHq79g3StxJW6zPC/144569/orbittour/script" id="orbittour-GSgzaPXHq79g3StxJW6zPC" />
        </div>
    );
};

export default ThreeD;