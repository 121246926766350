// Counting animation
function waitForMs(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}
async function makeCount() {
    let val = 0;
    let elts = document.querySelectorAll('.fe-countable');

    for (const element of elts) {
        const to = +element.getAttribute('data-count');

        // detecter si le nombre a une virgule
        const isFloat = !(Math.floor(to) === to);

        if (element.getBoundingClientRect().top <= 9 * window.innerHeight / 10 && !element.classList.contains('activated')) {
            element.classList.add('activated')
            while (val <= to) {
                val += (to / 10)
                if (isFloat) element.innerText = `${parseFloat(`${val}`).toFixed(1)}`;
                else element.innerText = `${parseFloat(`${val}`).toFixed(0)}`;

                if (val >= to) element.innerText = `${to}`;
                await waitForMs(150);
            }
        }
    }
}

window.onscroll = async function () {
    await makeCount();
}
window.onload = async function () {
    await makeCount();
}