import {useEffect, useState} from "react";

const AnimatedElement = (props) => {
    const {direction, children, classNames, type} = props;

    let classes = 'animated-element' + classNames;
     classes = classes + ` animated-element ${direction === 'left' ? 
                    'animate-left' : (direction === 'right' ?
                    'animate-right' : (direction === 'up' ? 'animate-down' : (direction === 'down' ? 'animate-up' : '')))}`;

    classes = type === 'bounce' ? classes + ' animate-bounce' : classes;

    const [active, setActive] = useState(false);

    function revealOnScroll() {
        let elements = document.querySelectorAll('.animated-element');

        for (let i = 0; i < elements.length; i++) {
            const windowHeight = window.innerHeight;
            const elementTop = elements[i].getBoundingClientRect().top;
            const visibleAt = 9 * window.innerHeight / 10

            if (elementTop < visibleAt) {
                elements[i].classList.add('active');
            }
        }
    }

    useEffect(() => {
        revealOnScroll()
        window.addEventListener('scroll', revealOnScroll);
        window.addEventListener('load', revealOnScroll);

        return () => {
            window.removeEventListener('load', revealOnScroll);
            window.removeEventListener('scroll', revealOnScroll);
        }

    }, [])

    return (
        <>
            <div className={classes}>
                {children}
            </div>
        </>
    );
}

export default AnimatedElement;