import React from 'react';
import MakitaAkkuschrauber from "./Content_components/Makita_akkuschrauber";
import List_product from "./Content_components/List_product";
import Banners from "./Content_components/Banners";
import Product_comparison from "./Content_components/Product_comparison";
import Next_generation_banner from "./Content_components/Next_generation_banner";
import ScrewdriverCompare from "./Content_components/Screwdriver_compare";
import Three_D from "./Content_components/Three_D";
import Element_populated from "./Content_components/Element_populated";
import Toolbrothers_user from "./Content_components/Toolbrothers_user";
import Map from "./Content_components/Map";
import Footer from "./Footer";
const Content = () => {
    return (
        <>
            <MakitaAkkuschrauber />
            <List_product />
            <Banners logo="images/lxt.png" textOrImage="ONE SYSTEM. ENDLESS POSSIBILITIES" link="https://toolbrothers.com/?match=all&subcats=Y&pcode_from_q=Y&pshort=Y&pfull=Y&pname=Y&pkeywords=Y&search_performed=Y&q=lxt&dispatch=products.search&security_hash=2ffb274d61a32a062d84228bc575e949" />
            <Product_comparison />
            <Next_generation_banner />
            <ScrewdriverCompare />
            <Three_D />
            <Element_populated />
            <Toolbrothers_user isContactInformation="yes" />
            <Map />
            <Footer bg="bg-black" />
        </>
    );
};

export default Content;