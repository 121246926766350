import React, {useEffect} from 'react';
import {Carousel} from "flowbite-react";
import ProductThreeD from "../Components/ThreeD/ProductThreeD";
import Map from "../Components/Content_components/Map";
import {Link} from "react-router-dom";
import AnimatedElement from "../Components/AnimatedElement";
import Footer from "../Components/Footer";

const MakitaDdf = () => {
    const productsCarousel = [
        {endpoint: "makita-ddf-481", image_url: "images/Banner_DDF481-1.gif"},
        {endpoint: "makita-ddf-484", image_url: "images/background-banner-elemente-schatten-2-1.gif"},
    ]
    return (
        <>
            <div  className="h-36 sm:h-[20rem] xl:h-[30rem] 2xl:h-[40rem]">
                <Carousel indicators={false} className="top-0 bottom-0">
                    {
                        productsCarousel.map((items, index) => {
                            return (
                                <Link key={index} to={`/schlagbohrschrauber/${items.endpoint}`}><img src={items.image_url} className="top-0" alt="..."/></Link>
                            )
                        })
                    }
                </Carousel>
            </div>
            <div className="md:flex container w-full justify-center justify-items-center items-center m-auto">
                <ProductThreeD title="Makita DHP 481" link="//orbitvu.co/share/6upuetu3LrXYCEVLqMMY8E/144569/orbittour/script" id="orbittour-6upuetu3LrXYCEVLqMMY8E" />
                <ProductThreeD title="Makita DHP 484" link="//orbitvu.co/share/NFUm6ZMeKnk5QnR4uer7f4/144569/orbittour/script" id="orbittour-NFUm6ZMeKnk5QnR4uer7f4" />
            </div>
            <div className="container items-center flex justify-center m-auto lg:my-[200px] my-[100px]">
                <div className="providers-container w-full flex justify-between items-center md:w-1/2 md:px-0 px-10">
                    <img src="/images/makita-1024x228.png" className="h-auto md:w-[35%] w-[40%] md:w-1/3 w-1/2" alt=""/>
                    <img src="/images/lxt_l_500x196_wd_custom.png" className="h-auto md:w-[35%] w-[40%] md:w-1/3 w-1/2" alt=""/>
                </div>
            </div>
            <div className="container m-auto hidden md:block text-white lg:my-[200px] my-[100px] px-[50px]">
                <div className="descriptions flex justify-between items-center text-center">
                    <div className="prop space-y-7 w-1/4 p-2">
                        <i className="fas fa-arrows-alt-h text-[55px] font-bold"></i>
                        <h3 className="text-[20px] font-light text-[#a0a0a0]">Rechts-Links Umschaltung</h3>
                    </div>
                    <div className="prop space-y-7 w-1/4 p-2">
                        <i aria-hidden="true" className="fas fa-battery-full text-[55px] font-bold"></i>
                        <h3 className="text-[20px] font-light text-[#a0a0a0]">Rechts-Links Umschaltung</h3>
                    </div>
                    <div className="prop space-y-7 w-1/4 p-2">
                        <i aria-hidden="true" className="fas fa-business-time text-[55px] font-bold"></i>
                        <h3 className="text-[20px] font-light text-[#a0a0a0]">Rechts-Links Umschaltung</h3>
                    </div>
                    <div className="prop space-y-7 w-1/4 p-2">
                        <i aria-hidden="true" className="fab fa-buffer text-[55px] font-bold"></i>
                        <h3 className="text-[20px] font-light text-[#a0a0a0]">Rechts-Links Umschaltung</h3>
                    </div>
                </div>
            </div>
            <div className="container m-auto md:flex justify-between items-center px-[50px] lg:my-[200px] my-[100px] relative">
                <AnimatedElement classNames="left text flex flex-col justify-center items-start space-y-7 md:w-1/2" direction="right">
                    <h3 className="text-[#a0a0a0] text-[27px]">Makita</h3>
                    <h2 className="lg:text-[40px] text-[28px] biggest text-white font-bold">Ersatzteile gesucht ?</h2>
                    <span>
                        <a className="text-[#39888A]" href="https://toolbrothers.com/">Jetzt beraten lassen > </a>
                    </span>
                </AnimatedElement>
                <div className="right gif-container md:w-1/2">
                    <img src="/images/DHP482-3Explosion.gif" alt="DHP482-3Explosion.gif"/>
                </div>
            </div>
            <Map/>
            <AnimatedElement classNames="container mx-auto my-[50px] md:p-10 px-3 text-white space-y-11 flex flex-col text-center justify-center items-center bg-[#0F0F0F] md:w-4/5" direction="right">
                <h1 className="font-bold lg:text-[40px] md:text-[25px] text-3xl">Das macht <a href="https://toolbrothers.com" className="text-[#39888A]">TOOLBROTHERS</a> so besonders</h1>
                <p className="font-bold lg:text-[40px] md:text-[25px] text-xl">
                    <a href="https://toolbrothers.com" className="text-[#39888A]">TOOLBROTHERS</a> – UNSER  <span className="text-[#ca3231]">♥</span> SCHLÄGT FÜR POWER-TOOLS!
                </p>
                <p className="font-light">
                    Wir möchten Ihnen helfen, sich in der riesigen Welt der Werkzeuge zurecht zu finden!
                    Was für ein Gerät brauche ich? Wie schlägt sich diese Maschine im Vergleich zu dieser?
                    Wo finde ich den besten Preis? Das sind die Hauptfragen, auf die Sie bei uns die richtigen
                    Antworten finden.
                </p>
                <p className="font-light">
                    Werden Sie Teil unserer Community!
                </p>
            </AnimatedElement>
            <div className="text-white mb-font-makita md:p-10 px-5 text-center justify-center justify-items-center items-center m-auto  w-full">
                <div className="container md:flex m-auto mt-10 md:space-x-10 md:space-y-0 space-y-6 text-white">
                    <div className="md:w-1/3 w-full m-auto p-2 bg-[#0f0f0f] space-y-7">
                        <img src="images/scott-graham.jpg" className="transition-all duration-300 cursor-pointer filter grayscale hover:grayscale-0" alt=""/>
                        <h1 className="text-3xl">
                            Kompetenter Partner
                        </h1>
                        <p>
                            Ein Fokus unseres Unternehmens liegt in der Belieferung von Businesskunden
                            aller Größen. Unser mehr als 30-köpfiges, ständig wachsendes Team unterstützt unsere Firmenkunden in allen Fragen.
                        </p>
                    </div>
                    <div className="md:w-1/3 w-full m-auto p-2 bg-[#0f0f0f] space-y-7">
                        <img src="images/marissa.jpg " className="transition-all duration-300 cursor-pointer filter grayscale hover:grayscale-0" alt=""/>
                        <h1 className="text-3xl">
                            Geschulte Mitarbeiter
                        </h1>
                        <p>
                            Der hohe Qualitätsstandard unserer Firma wird sowohl durch
                            regelmäßig durchgeführte interne Schulungen wie auch durch
                            Zertifizierungskurse der einzelnen Hersteller gewährleistet.
                        </p>
                    </div>
                    <div className="md:w-1/3 w-full m-auto p-2 bg-[#0f0f0f] space-y-7">
                        <img src="images/petr-machacek.jpg " className="transition-all duration-300 cursor-pointer filter grayscale hover:grayscale-0" alt=""/>
                        <h1 className="text-3xl">
                            Schneller Support
                        </h1>
                        <p>
                            Wir beraten Sie umfassend bei allen Fragen zu den von uns
                            angebotenen Produkten. Ob vor oder nach dem Kauf, unsere
                            qualifizierten Servicemitarbeiter stehen Ihnen gerne zur Verfügung.
                        </p>
                    </div>
                </div>
            </div>
            <Footer bg="bg-[#0f0f0f]" />
        </>
    );
};

export default MakitaDdf;