import React from 'react';
import {Link} from "react-router-dom";

const List_product = () => {
    return (
        <div className="md:flex text-center justify-center justify-items-center items-center text-white md:space-x-6 md:space-y-0 space-y-6 py-10  w-full container m-auto mb-font-makita md:px-0 px-7">
            <div className="md:w-1/2 w-full bg-[#181818] py-10 px-10 text-center justify-center justify-items-center items-center">
                <h1 className="lg:text-[35px] md:text-[25px] text-[16px] font-bold mb-shadow-makita-text">Handlich, kompakt und robust.</h1>
                <p className="md:text-[20px] lg:text-[26px] md:font-bold-500 md:mt-8 mt-3 text-[14px]">Genau das was du brauchst.</p>
                <h5 className="md:text-[19px] text-[13px] text-[#D1D1D1] md:font-bold-400 mt-4">Makita Bohrschrauber, lass dich von Qualität und Leistung verführen</h5>
                <img src="/images/abgeschnitt484-1024x681.png" className="md:mt-10 mt-5 md:mb-10 w-2/3 m-auto" alt=""/>
                <Link to="/category/bohrschrauber" className="text-[#39888A] md:mt-10" rel="noreferrer">mehr erfahren ></Link>
            </div>
            <div className="md:w-1/2 w-full bg-[#181818] py-10 px-10 text-center justify-center justify-items-center items-center">
                <h1 className="lg:text-[35px] md:text-[25px] text-[16px] font-bold mb-shadow-makita-text">Zuverlässig und leistungsstark</h1>
                <p className="md:text-[20px] lg:text-[26px] md:font-bold-500 md:mt-8 mt-3 text-[14px]">Genau das was du suchst.</p>
                <h5 className="md:text-[19px] text-[13px] text-[#D1D1D1] md:font-bold-400 mt-4">Makita Schlagbohrschrauber, vergleiche Sie und inspiriere dich</h5>
                <img src="/images/485i-1024x658.png" className="md:mt-10 mt-5 md:mb-10 w-2/3 m-auto" alt=""/>
                <Link to="/category/schlagbohrschrauber" className="text-[#39888A] md:mt-10" rel="noreferrer">mehr erfahren ></Link>
            </div>
        </div>
    );
};

export default List_product;