import React, {useEffect, useState} from 'react';
import {Link, NavLink} from "react-router-dom";
import Top from './Top';

function Header(props) {
    const [scroll, setScroll] = useState(false)
    const [navbar, setNavbar] = useState(false);
    const toggleMobileMenu = () => { setNavbar (!navbar); };

    useEffect(() => {
        window.addEventListener("scroll", () => {
            const topInformation = document.getElementById("mb-top-information").getBoundingClientRect()
            if (topInformation.bottom <= 0) {
                setScroll(true)
            }else {
                setScroll(false)
            }
        })
    }, [])

    const productsDdf = [
        {name: "Makita DDF 481", link: "makita-ddf-481"},
        {name: "Makita DDF 484", link: "makita-ddf-484"},
    ]

    const productsXgt = [
        {name: "Makita XGT DF001G", link: "makita-xgt-df001g"},
        {name: "Makita XGT HP001G", link: "makita-xgt-hp001g"},
    ]

    const productsDhp = [
        {name: "Makita DHP 453", link: "makita-dhp-453"},
        {name: "Makita DHP 458", link: "makita-dhp-458"},
        {name: "Makita DHP 481", link: "makita-dhp-481"},
        {name: "Makita DHP 484", link: "makita-dhp-484"},
        {name: "Makita DHP 485", link: "makita-dhp-485"},
        {name: "Makita DHP 482", link: "makita-dhp-482"},
        {name: "Makita DHP 456", link: "makita-dhp-456"},
    ]

    return (
        <div className="md:mx-0 mx-2 mx-auto">
            <div id="mb-top-information" className="m-auto justify-center items-center justify-items-center text-center">
                <Top />
            </div>

            <div id="navBarTop" className={` w-full  bg-[#181818] ${scroll ? `md:top-0 mt-5` : `top-0`}`} >
                <nav className={`px-2 sm:px-4 w-full z-40 bg-[#181818] ${scroll ? 'fixed top-0' : null}`}>
                    <div className="lg:container justify-between px-4 mx-auto  md:items-center md:flex md:px-8">
                        <div>
                            <div className="flex items-center justify-between md:block">
                                <Link to="/" className="md:p-0 py-[20px]">
                                    <img src="/logos/logo.png" className="h-5 mr-3 lg:h-9" alt="Flowbite Logo" />
                                </Link>
                                <div className="md:hidden flex space-x-14">
                                    <a href="tel:+4955199690988" type="button" className="block md:hidden md:mr-0 mt-2 ">
                                        <img src="/icons/call.png" height="30" width="30" alt=""/>
                                    </a>
                                    <button
                                        className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                                        onClick={() => setNavbar(!navbar)}
                                    >
                                        {navbar ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-6 h-6 text-white"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-6 h-6 text-white"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M4 6h16M4 12h16M4 18h16"
                                                />
                                            </svg>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                                className={`flex-1 justify-self-center md:block md:pb-0 md:mt-0 ${
                                    navbar ? "block" : "hidden"
                                }`}
                            >
                                <ul className="flex flex-col mt-4 space-y-2 md:space-y-0 md:flex-row lg:space-x-8 md:mt-0 md:text-sm">
                                    <li>
                                        <NavLink to="/makita-ddf-bohrschrauber" href="#" className={({ isActive }) =>
                                            (isActive ? "active-navBar py-0 md:py-[36px] peer lg:px-5 text-white md:py-[36px] block pl-3 pr-4 text-white hover:bg-gray-100 md:hover:bg-transparent transition duration-0 hover:duration-500 hover:text-[#39888A]" : "py-0 md:py-[36px] peer lg:px-5 text-white md:py-[36px] block pl-3 pr-4 text-white hover:bg-gray-100 md:hover:bg-transparent transition duration-0 hover:duration-500 hover:text-[#39888A]")}>
                                            DDF-BOHRSCHRAUBER
                                        </NavLink>
                                        <div className="w-full hidden mb-shadow-hover peer-hover:flex bg-[#181818] hover:flex lg:w-[250px] flex-col hover:text-[#0f0f0f] text-white md:absolute z-20 items-center justify-center justify-items-center">
                                            {
                                                productsDdf.map((items, index) => {
                                                    return (
                                                        <NavLink onClick={toggleMobileMenu} className={({ isActive }) =>
                                                            (isActive ? "active-navBar-toggle px-5 py-3 hover:bg-white w-full transition duration-0 hover:duration-500 hover:text-[#39888A] text-white font-bold top-0" : "px-5 py-3 hover:bg-white w-full transition duration-0 hover:duration-500 hover:text-[#39888A] text-white font-bold top-0")} key={index} to={`/schlagbohrschrauber/${items.link}`}>{items.name}</NavLink>
                                                    )
                                                })
                                            }
                                        </div>
                                    </li>
                                    <li>
                                        <NavLink to="/makita-40-volt-max" className={({ isActive }) =>
                                            (isActive ? "active-navBar py-0 md:py-[36px] peer lg:px-5 text-white md:py-[36px] block pl-3 pr-4 text-white hover:bg-gray-100 md:hover:bg-transparent transition duration-0 hover:duration-500 hover:text-[#39888A]" : "py-0 md:py-[36px] peer lg:px-5 text-white md:py-[36px] block pl-3 pr-4 text-white hover:bg-gray-100 md:hover:bg-transparent transition duration-0 hover:duration-500 hover:text-[#39888A]")}>
                                            DHP-Schlagbohrschrauber</NavLink>
                                        <div className="hidden mb-shadow-hover peer-hover:flex bg-[#181818] hover:flex w-[250px] flex-col hover:text-[#0f0f0f] text-white md:absolute z-20 items-center justify-center justify-items-center">
                                            {
                                                productsDhp.map((items, index) => {
                                                    return (
                                                        <NavLink onClick={toggleMobileMenu} className={({ isActive }) =>
                                                            (isActive ? "active-navBar-toggle px-5 py-3 hover:bg-white w-full transition duration-0 hover:duration-500 hover:text-[#39888A] text-white font-bold top-0" : "px-5 py-3 hover:bg-white w-full transition duration-0 hover:duration-500 hover:text-[#39888A] text-white font-bold top-0")} key={index} to={`/schlagbohrschrauber/${items.link}`}>{items.name}</NavLink>
                                                    )
                                                })
                                            }
                                        </div>
                                    </li>
                                    <li>
                                        <NavLink to="/makita-40-volt-max-xgt-serie" className={({ isActive }) =>
                                            (isActive ? "active-navBar py-0 md:py-[36px] peer lg:px-5 text-white md:py-[36px] block pl-3 pr-4 text-white hover:bg-gray-100 md:hover:bg-transparent transition duration-0 hover:duration-500 hover:text-[#39888A]" : "py-0 md:py-[36px] peer lg:px-5 text-white md:py-[36px] block pl-3 pr-4 text-white hover:bg-gray-100 md:hover:bg-transparent transition duration-0 hover:duration-500 hover:text-[#39888A]")}>
                                            XGT® ?
                                        </NavLink>
                                        <div className="hidden mb-shadow-hover peer-hover:flex bg-[#181818] hover:flex w-[250px] flex-col hover:text-[#0f0f0f] text-white md:absolute z-20 items-center justify-center justify-items-center">
                                            {
                                                productsXgt.map((items, index) => {
                                                    return (
                                                        <NavLink onClick={toggleMobileMenu} className={({ isActive }) =>
                                                            (isActive ? "active-navBar-toggle px-5 py-3 hover:bg-white w-full transition duration-0 hover:duration-500 hover:text-[#39888A] text-white font-bold top-0" : "px-5 py-3 hover:bg-white w-full transition duration-0 hover:duration-500 hover:text-[#39888A] text-white font-bold top-0")} key={index} to={`/bohrschrauber/${items.link}`}>{items.name}</NavLink>
                                                    )
                                                })
                                            }
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className="hidden space-x-2 md:inline-block">
                            <a href="https://toolbrothers.com/" type="button" className="mb_font_to text-white bg-[#39888A] hover:text-[#39888A] lg:block hidden transition duration-0 hover:duration-300 hover:bg-white rounded-full text-xs lg:text-sm lg:px-8  px-4 py-3 md:py-2 text-center mr-3 md:mr-0">
                                ZUM VERGLEICHSPORTAL
                            </a>

                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Header;