import React from 'react';
import {Carousel} from "flowbite-react";
import {Link} from "react-router-dom";
import Banners from "../../Components/Content_components/Banners";
import ScrewdriverCompare from "../../Components/Content_components/Screwdriver_compare";
import Map from "../../Components/Content_components/Map";
import Makita from "../../Components/Makita";
import ToolbrothersUser from "../../Components/Content_components/Toolbrothers_user";
import AnimatedElement from "../../Components/AnimatedElement";
import Footer from "../../Components/Footer";

const Ddf = () => {
    const productsCarousel = [
        {endpoint: "makita-ddf-481", image_url: "../images/Banner_DDF481-1.gif"},
        {endpoint: "makita-ddf-484", image_url: "../images/background-banner-elemente-schatten-2-1.gif"},
    ]

    return (
        <div className="text-white">
            <section  className="h-36 sm:h-[20rem] xl:h-[30rem] 2xl:h-[40rem] mb-10">
                <Carousel indicators={false} leftControl={true} rightControl={true} className="top-0 bottom-0">
                    {
                        productsCarousel.map((items, index) => {
                            return (
                                <Link key={index} to={`/schlagbohrschrauber/${items.endpoint}`}><img src={items.image_url} className="top-0" alt="..."/></Link>
                            )
                        })
                    }
                </Carousel>
            </section>
            <Banners logo="../images/lxt.png" textOrImage="ONE SYSTEM. ENDLESS POSSIBILITIES" link="https://toolbrothers.com/?match=all&subcats=Y&pcode_from_q=Y&pshort=Y&pfull=Y&pname=Y&pkeywords=Y&search_performed=Y&q=lxt&dispatch=products.search&security_hash=2ffb274d61a32a062d84228bc575e949" />
            <ScrewdriverCompare />
            <section className=" mt-[5%] md:px-0 px-3 bg-[#121317] py-8">
                <div className="lg:md:w-3/5 md:md:w-4/5 text-white m-auto items-center justify-center container">
                    <div className="md:flex justify-center items-center justify-items-center lg:space-x-0 md:space-x-4 relative">
                        <AnimatedElement classNames="md:w-1/2 w-4/5 m-auto items-center justify-center" direction="down">
                            <h1 className="xl:text-[40px] lg:text-[40px] md:text-[30px] text-2xl font-semibold md:my-10 mb-5">Anwendungsbereiche</h1>
                        </AnimatedElement>
                        <div className="md:w-1/2 lg:p-10 md:p-5 p-7 bg-black text-[#A0A0A0] w-4/5 m-auto items-center justify-center">
                            <p>Holz, Metall, Keramik und Kunststoff</p>
                        </div>
                    </div>
                </div>
            </section>
            <Makita/>
            <Map />
            <div className="md:px-0 px-5">
                <ToolbrothersUser isBesonders="yes"/>
            </div>
            <Footer bg="bg-[#0f0f0f]" />
        </div>
    );
};

export default Ddf;