import React from 'react';
import Map from "../../Components/Content_components/Map";
import {Link} from "react-router-dom";
import Footer from "../../Components/Footer";

const Contact = () => {
    return (
        <>
            <div className="md:px-0 px-3">
                <div className="text-white m-auto items-center justify-center container md:w-1/2 text-center">
                    <h1 className="xl:text-[40px] lg:text-[35px] md:text-[25px] text-2xl mt-[8%] font-semibold text-center">Kontaktieren Sie uns!</h1>
                    <div className="mt-10">
                        <p className="">
                            Sie haben Fragen oder benötigen anderweitig unsere Hilfe?
                        </p>
                        <p className="mt-5">
                            Wir helfen Ihnen gerne weiter.
                        </p>
                    </div>
                    <div className="mt-10 mb-[10%]">
                        <form action="#">
                            <div className="mb-6">
                                <input placeholder="Name" name="name" type="text" id="large-input" className="block w-full p-3 text-gray-900 bg-gray-50 sm:text-md focus:ring-black focus:border-0" />
                            </div>
                            <div className="mb-6">
                                <input name="email" placeholder="E-mail" type="email" id="large-input" className="block w-full p-3 text-gray-900 bg-gray-50 sm:text-md focus:ring-black focus:border-0" />
                            </div>
                            <div className="mb-6">
                                <textarea name="content" rows="3" placeholder="Nachricht" className="block w-full p-3 text-gray-900 bg-gray-50 sm:text-md focus:ring-black focus:border-0"></textarea>
                            </div>
                            <div className="mb-6">
                                <input placeholder="Name" name="name" type="submit" id="large-input" className="block transition duration-0 hover:duration-500 text-white w-full p-3 text-gray-900 bg-[#39888A] sm:text-md focus:ring-black focus:border-0 font-bold hover:bg-white hover:text-[#39888A]" value="SENDEN" />
                            </div>
                        </form>
                    </div>
                </div>
                <Map />
            </div>
            <Footer bg="bg-black" />
        </>

    );
};

export default Contact;