import React from 'react';
import {Link} from "react-router-dom";

const NextGenerationBanner = () => {
    return (
        <div className="w-full  text-white py-5 mb-font-makita mb-shadow-banner mb-10">
            <div className="container m-auto flex text-center justify-center justify-items-center items-center w-full">
                <div className="md:w-2/3 w-full text-center items-center m-auto justify-center justify-items-center">
                    <img src="images/bildschirmf.png" className="m-auto md:w-auto md:w-1/2 m-auto" alt=""/>
                </div>
                <div className="md:w-1/3 w-full">
                    <Link to="/" className="text-[#39888A] m-auto md:text-auto md:text-[17px] m-auto" rel="noreferrer">Jetzt Entdecken ></Link>
                </div>
            </div>
        </div>
    );
};

export default NextGenerationBanner;