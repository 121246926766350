import React from 'react';

const Map = () => {
    return (
        <div className=" w-full py-[10px] flex">
            <div className="m-auto w-full">
                <iframe width="100%" height="300" id="gmap_canvas" src="https://maps.google.com/maps?q=Dinotech e.K.&t=&z=14&ie=UTF8&iwloc=&output=embed"></iframe>
            </div>
        </div>
    );
};

export default Map;