import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import ProductThreeD from "../Components/ThreeD/ProductThreeD";
import Rechts from "../Components/Rechts";
import Multifunctional from "../Components/Multifunctional";
import Counter from "../Components/Counter";
import AnimatedElement from "../Components/AnimatedElement";
import LaunchBar from "../Components/LaunchBar";
import Footer from "../Components/Footer";
import Makita from "../Components/Makita";

const ViewProduct = () => {

    const { endpoint } = useParams();

    console.log(endpoint)

    const products = [
        {
            endpoint: "makita-dhp-453",
            image_url: "../images/DHP453_Banner.gif",
            title: "Akku Schlagbohrschrauber mit Komfort",
            script_url_three_d: "",
            id: "",
            threed_title: "Makita DHP 453",
            description_three_d: [
                {title: "Gummiert", description: " Gummierter Handgriff sorgt für einen sicheren Halt "},
                {title: "2-Gang-Getriebe", description: " 2-Gang-Planetengetriebe mit Metallzahnrädern"}
            ],
            Power: [
                {name: 'Stahl', value: '13 mm'},
                {name: 'Mauerwerk', value: '13 mm'},
                {name: 'Holz', value: '36 mm'},
            ],
            weight: {battery: "1.5", weight_including : "1.7"},
            couple: {weich: '27 Nm', hart: '42 Nm'},
            level: {Schalldruckpegel: "83", Schallleistungspegel : "94"},
            link: "https://toolbrothers.com/elektrowerkzeug-de/schrauber-and-bohrschrauber/schlagbohrschrauber/akku-schlagbohrschrauber/makita-dhp484z-akku-schlagbohrschrauber-18v-brushless-1-2-54nm-doppelbit-ohne-akku-ohne-ladegerat/",
            description: "Der Makita DHP 453 Akku Schlagbohrschrauber verfügt über ein maximales Drehmoment von 42 Nm und ein präzises 2-Gang-Getriebe. Er ist durch seine stufenlose Drehzahlsteuerung auf 16 Stufen + Bohrstufe, sowie seine elektronisch regelbare Drehmomentbegrenzung auch für feine Bohrarbeiten geeignet.\n" +
                        "Zusätzlich dazu ist dieser 18V Schlagbohrschrauber mit einem abschaltbarem Schlagwerk und und einem Rechts- /Linkslauf ausgestattet. Die Motorbremsen des DHP 453 garantieren mehr Kontrolle und ermöglichen exakte Schraubarbeiten. Das Schnellspannbohrfutter ermöglicht werkzeuglosen Wechsel der Einsatzwerkzeuge.\n" +
                        "Durch die kompakte Bauweise in Verbindung mit dem ergonomischen, gummierten Handgriff hat dieser Akkuschrauber in unserem Test auch im Komfortbereich vollends überzeugt."
        },
        {
            endpoint: "makita-dhp-456",
            image_url: "../images/DHP456_Banner.gif",
            title: "Vielseitig einsetzbar",
            script_url_three_d: "//orbitvu.co/share/k9PkTiLqoZ4xt2pUegL7W7/144569/orbittour/script",
            id: "orbittour-k9PkTiLqoZ4xt2pUegL7W7",
            threed_title: "Makita DHP 456",
            description_three_d: [
                {title: "2-Gang-Getriebe", description: " 2-Gang-Planetengetriebe mit Metallzahnrädern"},
                {title: "LED", description: "integriertes LED-Licht zum Ausleuchten der Arbeitsstelle"}
            ],
            Power: [
                {name: 'Stahl', value: '13 mm'},
                {name: 'Mauerwerk', value: '13 mm'},
                {name: 'Holz', value: '38 mm'},
            ],
            couple: {weich: '36 Nm', hart: '50 Nm'},
            weight: {battery: "1.3", weight_including : "1.6"},
            level: {Schalldruckpegel: "81", Schallleistungspegel : "92"},
            link: "https://toolbrothers.com/?match=all&subcats=Y&pcode_from_q=Y&pshort=Y&pfull=Y&pname=Y&pkeywords=Y&search_performed=Y&q=schlagbohrschrauber&dispatch=products.search&security_hash=2ffb274d61a32a062d84228bc575e949",
            description: "Der DHP 456 von Makita ist vielseitig einsetzbar. Zum schlagbohren in verschiedene Materialien wie beispielsweise Ziegel und Beton oder zum bohren und schrauben in Holz, Metall und Kunststoff. Der 18V Schlagbohrschrauber verfügt über ein maximales Drehmoment von 50 Nm und ist dabei in 16 Drehmomentstufen + Bohrstufe und abschaltbarem Schlagwerk einstellbar. Das eingebaute 2-Gang-Vollmetall-Planetengetriebe sorgt für optimale Drehmomentübertragung. Der eingebaute Rechts- / Linkslauf erleichtert das Ein- und Ausdrehen der Schrauben. Die Motorbremse ermöglicht genaue Schraubarbeiten. Um die Arbeit in vielen Bereichen zu erleichtern, wurde eine Akku-Kapazitätsanzeige und eine leuchtstarke LED zum Ausleuchten des Arbeitsbereich integriert. Das Schnellspannbohrfutter ermöglicht einen werkzeuglosen Einsatzwerkzeugwechsel. Um einen angenehmen Anwendungskomfort zu gewährleisten, wurde ein Softgriff verbaut.\n" +
                         "Das Ergebnis in unserem Test: Der DHP 456 ist ein solider Allrounder mit angenehmen Anwendungskomfort im Bereich der Schlagbohrschrauber."
        },
        {
            endpoint: "makita-dhp-458",
            image_url: "../images/DHP458_Banner.gif",
            title: "Der Akku Schlagbohrschrauber mit Power!",
            script_url_three_d: "//orbitvu.co/share/d5m2cUECLR7nsD7W9uZ8fS/144569/orbittour/script",
            id: "orbittour-d5m2cUECLR7nsD7W9uZ8fS",
            threed_title: "Makita DHP 458",
            description_three_d: [
                {title: "XPT", description: "eXtreme Protection Technology schützt die Maschine vor Staub und Spritzwasser"},
                {title: "2-Gang-Getriebe", description: " 2-Gang-Planetengetriebe mit Metallzahnrädern"},
                {title: "LED", description: " integriertes LED-Licht zum Ausleuchten der Arbeitsstelle"}
            ],
            Power: [
                {name: 'Stahl', value: '13 mm'},
                {name: 'Mauerwerk', value: '16 mm'},
                {name: 'Holz', value: '76 mm'},
            ],
            weight: {battery: "2.3", weight_including : "2.6"},
            level: {Schalldruckpegel: "84", Schallleistungspegel : "95"},
            link: "https://toolbrothers.com/elektrowerkzeug-de/schrauber-and-bohrschrauber/schlagbohrschrauber/akku-schlagbohrschrauber/makita-dhp484z-akku-schlagbohrschrauber-18v-brushless-1-2-54nm-doppelbit-ohne-akku-ohne-ladegerat/",
            description: "Mit dem DHP 458 hat Makita eine kraftvollen und leistungsstarken 18V Schlagbohrschrauber auf den Markt gebracht. Mit dem 2-Gang-Vollmetall-Planetengetriebe und der regulierbaren Drehzahl in 21 Stufen mit zusätzlicher Bohrstufe und abschaltbarem Schlagwerk können in den verschiedensten Situationen optimale Ergebnisse erzielt werden. Der Rechts- / Linkslauf der Maschine unterstützt nochmal zusätzlich. Mit einem maximalen Drehmoment von 91 Nm ist dieser Schlagbohrschrauber ein Kraftpaket mit kompakter Bauform. Dank der verbesserten Sicherheit gegen Staub und Spritzwasser eignet sich dieses Modell auch für den Außeneinsatz oder die Verwendung in rauer, staubiger Umgebung. Dank der eingebauten Motorbremse werden volle Kontrolle und exakte Schraubarbeiten ermöglicht. Die Akku-Kapazitätsanzeige und die leuchtstarke Doppel-LED mit Nachglimmfunktion sorgen für einen angenehmen Anwendungskomfort. Durch das Schnellspannbohrfutter kann das Einsatzwerkzeug werkzeuglos gewechselt werden.\n" +
                         "Fazit nach unserem Test: Der DHP 458 ist ein kraftvoller und leistungsstarker Schlagbohrschrauber, der auch für den Außeneinsatz unter rauen Bedingungen geeignet ist."
        },
        {
            endpoint: "makita-dhp-481",
            image_url: "../images/DHP481_Banner.gif",
            title: "Der Akku-Schlagbohrschrauber mit 115 Nm!",
            script_url_three_d: "//orbitvu.co/share/6upuetu3LrXYCEVLqMMY8E/144569/orbittour/script",
            id: "orbittour-6upuetu3LrXYCEVLqMMY8E",
            threed_title: "Makita DHP 481",
            description_three_d: [
                {title: "Brushless", description: "mehr Ausdauer und mehr Leistung bei längerer Lebensdauer"},
                {title: "2-Gang-Getriebe", description: " 2-Gang-Planetengetriebe mit Metallzahnrädern"},
                {title: "LED", description: " integriertes LED-Licht zum Ausleuchten der Arbeitsstelle"}
            ],
            Power: [
                {name: 'Stahl', value: '13 mm'},
                {name: 'Mauerwerk', value: '13 mm'},
                {name: 'Holz', value: '76 mm'},
            ],
            couple: {weich: '60 Nm', hart: '115 Nm'},
            weight: {battery: "2.3", weight_including : "2.6"},
            level: {Schalldruckpegel: "85", Schallleistungspegel : "96"},
            link: "https://toolbrothers.com/elektrowerkzeug-de/schrauber-and-bohrschrauber/bohrschrauber/akku-bohrschrauber/makita-ddf481z-akku-bohrschrauber-18v-brushless-115nm-bit-and-bohrer-ohne-akku-ohne-ladegerat/",
            description: "Der DHP 481 von Makita ist ein leistungsstarker 18V Akku-Schlagbohrschrauber. Das 2-Gang-Vollmetall-Planetengetriebe und der kraftvolle Motor sorgen für optimale Drehmomentübertragung bei 21-facher Drehmomenteinstellung (+ Bohrstufe und abschaltbarem Schlagwerk). Der bürstenlose Motor sorgt für eine längere Lebensdauer und ermöglicht gleichzeitig mehr Leistung und Ausdauer.\n" +
                        "Zusätzlich zu seinem leistungsstarken Motor ist der DHP 481 noch mit einigen Extras ausgestattet. Er verfügt über einen Tiefentladeschutz, der den Akku schont, eine praktische und gut einsehbare Akku-Kapazitätsanzeige und eine leuchtstarke Doppel-LED.\n" +
                        "Ergebnis nach unserem Test: Ein kraftvoller Schlagbohrschrauber mit leistungsstarken Motor und hoher Ausdauer für intensive Arbeiten. "
        },
        {
            endpoint: "makita-dhp-484",
            image_url: "../images/DHP484_Banner.gif",
            title: "Ein Klassiker unter den Schlagbohrschraubern",
            script_url_three_d: "//orbitvu.co/share/NFUm6ZMeKnk5QnR4uer7f4/144569/orbittour/script",
            id: "orbittour-NFUm6ZMeKnk5QnR4uer7f4",
            threed_title: "Makita DHP 484",
            description_three_d: [
                {title: "Brushless", description: "mehr Ausdauer und mehr Leistung bei längerer Lebensdauer"},
                {title: "2-Gang-Getriebe", description: " 2-Gang-Planetengetriebe mit Metallzahnrädern"},
                {title: "LED", description: "integriertes LED-Licht zum Ausleuchten der Arbeitsstelle"}
            ],
            Power: [
                {name: 'Stahl', value: '13 mm'},
                {name: 'Mauerwerk', value: '13 mm'},
                {name: 'Holz', value: '38 mm'},
            ],
            couple: {weich: '30 Nm', hart: '54 Nm'},
            weight: {battery: "1.3", weight_including : "1.6"},
            level: {Schalldruckpegel: "88", Schallleistungspegel : "99"},
            link: "https://toolbrothers.com/elektrowerkzeug-de/schrauber-and-bohrschrauber/schlagbohrschrauber/akku-schlagbohrschrauber/makita-dhp484z-akku-schlagbohrschrauber-18v-brushless-1-2-54nm-doppelbit-ohne-akku-ohne-ladegerat/",
            description: "Makita hat mit dem DHP 484 Akku-Schlagbohrschrauber einen soliden, kompakten Alleskönner für die meisten Aufgaben auf den Markt gebracht. Er eignet sich zum schlagbohren in Ziegel, Beton und Stein und kann gleichzeitig zum schrauben und bohren in Metall, Holz, Kunststoff und ähnlichen Materialien verwendet werden. Der 18V Akkuschrauber arbeitet mit einem 2-Gang-Vollmetall-Planetengetriebe. Das maximale Drehmoment beträgt 54 Nm und ist in 21 Stufen, mit zusätzlicher Bohrstufe und abschaltbarem Schlagwerk, einstellbar. Der Motor des DHP ist bürstenlos (brushless) und arbeitet somit effizient und nahezu verschleißfrei. Um den Akku zu schonen, wurde ein Tiefentladeschutz integriert. Die leuchtstarke LED mit Nachglimmfunktion erleichtert das arbeiten in dunklen Bereichen.\n" +
                         "Der Akku Schlagbohrschrauber in unserem Test: Der DHP 484 ist ein kompakter und zuverlässiger Allrounder mit allen Funktionen, die für alltäglich anfallende Aufgaben benötigt werden."
        },
        {
            endpoint: "makita-dhp-485",
            image_url: "../images/DHP485_Banner-1.gif",
            title: "Handlich, Kompakt und bürstenlos",
            script_url_three_d: "//orbitvu.co/share/NuFyvVSRvAqryAgT4FNpeg/144569/orbittour/script",
            id: "orbittour-NuFyvVSRvAqryAgT4FNpeg",
            threed_title: "Makita DHP 485",
            description_three_d: [
                {title: "XPT", description: "eXtreme Protection Technology schützt die Maschine vor Staub und Spritzwasser"},
                {title: "2-Gang-Getriebe", description: " 2-Gang-Planetengetriebe mit Metallzahnrädern"},
                {title: "LED", description: " integriertes LED-Licht zum Ausleuchten der Arbeitsstelle"}
            ],
            Power: [
                {name: 'Stahl', value: '13 mm'},
                {name: 'Mauerwerk', value: '13 mm'},
                {name: 'Holz', value: '38 mm'},
            ],
            couple: {weich: '25 Nm', hart: '50 Nm'},
            weight: {battery: "1.5", weight_including : "1.7"},
            level: {Schalldruckpegel: "93", Schallleistungspegel : "82"},
            link: "https://toolbrothers.com/elektrowerkzeug-de/schrauber-and-bohrschrauber/schlagbohrschrauber/akku-schlagbohrschrauber/makita-dhp485z-akku-schlagbohrschrauber-18v-1-2-50nm-ohne-akku-ohne-ladegerat/",
            description: "Mit dem DHP 485 hat Makita einen kompakten Schlagbohrschrauber für alltägliche Einsätze rausgebracht. Er ist zum schlagbohren in Ziegel, Ziegelwerk und Mauerwerk, sowie zum bohren in Holz, Kunstoff, Metall und Keramik vorgesehen. Das 2-Gang-Vollmetall-Planetengetriebe der Maschine sorgt für optimale Drehmomentübertragung, der kompakte, bürstenlose Motor für lange Ausdauer bei nahezu verschleißfreier Arbeit. Das maximale Drehmoment beträgt moderate 50 Nm und ist in 21 Stufen (+ Bohrstufe und abschaltbarem Schlagwerk) feinfühlig regulierbar. Der eingebaute Tiefentladeschutz schont den Akku, indem es ihn vor kompletter Entladung abschaltet. Die leuchtstarke LED kann in dunklen Ecken zum Ausleuchten des Arbeitsbereich genutzt werden.\n" +
                         "Das Fazit nach unserem Test: Der DHP 485 ist ein kompakter und handlicher Akku Schlagbohrschrauber mit moderater Power."
        },
        {
            endpoint: "makita-dhp-482",
            video_url: "/videos/Körper-w-Rollback-HB.m4v",
            video_url2: "/videos/LED-w-Rollback-HB.m4v",
            title: "Universell einsetzbar",
            title_video: "Makita DHP 482",
            description_video: "18 V Li-Ion Akku Schlagbohrschrauber",
            script_url_three_d: "//orbitvu.co/share/NuFyvVSRvAqryAgT4FNpeg/144569/orbittour/script",
            id: "orbittour-NuFyvVSRvAqryAgT4FNpeg",
            threed_title: "Makita DHP 485",
            description_three_d: [
                {title: "XPT", description: "eXtreme Protection Technology schützt die Maschine vor Staub und Spritzwasser"},
                {title: "2-Gang-Getriebe", description: " 2-Gang-Planetengetriebe mit Metallzahnrädern"},
                {title: "LED", description: " integriertes LED-Licht zum Ausleuchten der Arbeitsstelle"}
            ],
            Power: [
                {name: 'Stahl', value: '13 mm'},
                {name: 'Mauerwerk', value: '13 mm'},
                {name: 'Holz', value: '38 mm'},
            ],
            couple: {weich: '36 Nm', hart: '62 Nm'},
            weight: {battery: "1.5", weight_including : "1.8"},
            level: {Schalldruckpegel: "93", Schallleistungspegel : "82"},
            link: "https://toolbrothers.com/elektrowerkzeug-de/schrauber-and-bohrschrauber/schlagbohrschrauber/akku-schlagbohrschrauber/makita-dhp482z-akku-schlagbohrschrauber-18v-62nm-ohne-akku-ohne-ladegerat/",
            description: "Der DHP 482 von Makita ist ein universell einsetzbarer 18V Akku-Schlagbohrschrauber mit 2-Gang-Vollmetall-Planetengetriebe und 4-poligem Gleichstrommotor für optimale Drehmomentübertragung bei einem maximalen Drehmoment von soliden 62 Nm. Er verfügt über 21 Drehmomenteinstellungen mit zusätzlicher Bohrstufe und abschaltbarem Schlagwerk.\n" +
                "Der Schraubbetrieb ist für herkömmliche Materialien wie Holz, Stahl und Kunststoff bestens geeignet, die Schlagbohrfunktion für Ziegel, Beton und sogar Stein.\n" +
                "Um bei dem vielfältigem Anwendungsbereich stets geschützt zu sein, ist der Akkuschrauber mit der XPT (Xtreme Protection Technology) ausgerüstet, welche das Innenleben der Maschine vor Staub und Spritzwasser schützt, wodurch die Lebensdauer merklich erhöht wird. Er verfügt darüber hinaus über ein 13 mm Schnellspannbohrfutter und eine integrierte Doppel-LED mit Nachglimmfunktion, die das Arbeiten auch in dunklen Bereichen ermöglicht. Der eingebaute Tiefentladeschutz schont den Akku, indem er ihn kurz vor kompletter Entladung abschaltet. Der Rechts- / Linkslauf ermöglicht das kontrollierte Ein- und Ausdrehen von Schrauben.\n" +
                "Fazit nach unserem Test: Der DHP 482 ist ein kräftiger und universell einsetzbarer Allrounder zum schrauben, bohren und schlagbohren im Innen- und Außeneinsatz."
        },

        //dft product object
        {
            endpoint: "makita-ddf-484",
            image_url: "../images/background-banner-elemente-schatten-2-1.gif",
            title: "Bürstenlos und robust",
            script_url_three_d: "//orbitvu.co/share/cc9bzrGnLomcdHwYECax6V/144569/orbittour/script",
            id: "orbittour-cc9bzrGnLomcdHwYECax6V",
            threed_title: "Makita DHP 484",
            description_three_d: [
                {title: "2-Gang-Getriebe", description: " 2-Gang-Planetengetriebe mit Metallzahnrädern"},
                {title: "LED", description: " integriertes LED-Licht zum Ausleuchten der Arbeitsstelle"}
            ],
            Power: [
                {name: 'Stahl', value: '13 mm'},
                {name: 'Holz', value: '38 mm'},
            ],
            couple: {weich: '30 Nm', hart: '54 Nm'},
            weight: {battery: "1.5", weight_including : "1.8"},
            level: {Schalldruckpegel: "76", Schallleistungspegel : "3"},
            link: "https://toolbrothers.com/elektrowerkzeug-de/schrauber-and-bohrschrauber/bohrschrauber/akku-bohrschrauber/makita-ddf484z-akku-bohrschrauber-18v-brushless-54nm-bit-and-bohrer-ohne-akku-ohne-ladegerat/",
            description: "Der DDF 484 von Makita ist ein 18V Akku Bohrschrauber mit einem robusten Gehäuse auf Aluminium-Druckguss. Dadurch macht er so einiges im Einsatz mit. Er verfügt über einen bürstenlosen (brushless) Motor, der für eine längere Ausdauer mit einer Akku-Ladung, für eine längere Lebensdauer und eine kompaktere Bauweise von grade einmal 172 mm Gehäuselänge sorgt. Das 2-Gang-Vollmetall-Planetengetriebe verspricht optimale Drehmomentübertragung bei 21 Drehmomentstufen (+ Bohrstufe), die selbst regulierbar sind. Der Bohrschrauber hat ein maximales Drehmoment von 54 Nm. Zusätzlich dazu verfügt der Akkuschrauber noch über einige interessante Features. Er ist mit einer Motorbremse für präzise Serienverschraubungen und mit einem Tiefentladeschutz, der den Akku schonen soll, ausgestattet. Um auch in dunklen Ecken arbeiten zu können, ist der DDF 484 mit einer leuchtstarken Doppel-LED ausgerüstet.\n" +
                        "Fazit nach unserem Test: Der DDF 484 ist ein robuster Akku-Bohrschrauber, der vielfältig einsetzbar ist."
        },
        {
            endpoint: "makita-ddf-481",
            image_url: "../images/Banner_DDF481-1.gif",
            title: "Kraftvoll und kompakt",
            script_url_three_d: "//orbitvu.co/share/yse7eoqE4tYghBNaGo4gki/144569/orbittour/script",
            id: "orbittour-yse7eoqE4tYghBNaGo4gki",
            threed_title: "Makita DHP 481",
            description_three_d: [
                {title: "2-Gang-Getriebe", description: " 2-Gang-Planetengetriebe mit Metallzahnrädern"},
                {title: "LED", description: " integriertes LED-Licht zum Ausleuchten der Arbeitsstelle"}
            ],
            Power: [
                {name: 'Stahl', value: '13 mm'},
                {name: 'Holz', value: '76 mm'},
            ],
            couple: {weich: '60 Nm', hart: '115 Nm'},
            weight: {battery: "1.8", weight_including : "2.1"},
            level: {Schalldruckpegel: "73", Schallleistungspegel : "3"},
            link: "https://toolbrothers.com/elektrowerkzeug-de/schrauber-and-bohrschrauber/bohrschrauber/akku-bohrschrauber/makita-ddf481z-akku-bohrschrauber-18v-brushless-115nm-bit-and-bohrer-ohne-akku-ohne-ladegerat/",
            description: "\n" +
                "Performance\n" +
                "Kraftvoll und kompakt\n" +
                "Der Makita DDF 481 ist ein sehr kraftvoller und gleichzeitig kompakter Akku Bohrschrauber, der zum Bohren und Schrauben in Holz, Kunststoff und Metall entwickelt wurde. Der bürstenlose (brushless) Motor überzeugt durch seine lange Lebensdauer dank verschleißarmer Arbeitsweise.\n" +
                "Der Akkuschrauber verfügt über ein maximales Drehmoment von satten 115 Nm und ein 2-Gang-Vollmetall-Planetengetriebe, das für eine optimale Übertragung hoher Drehmomente sorgt. Das Drehmoment lässt sich in 21 Stufen (+ Bohrstufe), passend für jeden Einsatz, einstellen. Trotz der kompakten Bauweise wurde nicht auf Extras, wie eine Akku-Kapazitätsanzeige, die den Ladestand des 18V Akkus anzeigt oder eine leuchtstarke LED mit Nachglimmfunktion, verzichtet.\n" +
                "Nach unserem Test lässt sich klar sagen: Dieser Makita Akkubohrschrauber ist ideal für Anwendungen, die ein hohes Drehmoment fordern."
        },

        //XGT products object

        {
            endpoint: "makita-xgt-df001g",
            image_url: "../images/df001.png",
            title: "Der Akku-Bohrschrauber mit noch mehr Power!",
            script_url_three_d: null,
            id: null,
            description_three_d: [
                {title: "Brushless", description: "für mehr Ausdauer, eine längere Lebensdauer und eine kompaktere Bauweise"},
                {title: "2-Gang-Getriebe", description: " 2-Gang-Planetengetriebe mit Metallzahnrädern"},
                {title: "LED", description: " integriertes LED-Licht zum Ausleuchten der Arbeitsstelle"}
            ],
            Power: [
                {name: 'Stahl', value: '20 mm'},
                {name: 'Holz', value: '50 mm'},
            ],
            weight: {battery: "3"},
            couple: {weich: '68 Nm', hart: '140 Nm'},
            level: {Schallleistungspegel : "75"},
            link: "https://www.makita.de/xgt.html",
            description: "Mit dem DF001G hat Makita einen kraftvollen und ausdauernden 40V Akku Bohrschrauber für leistungsintensive Anforderungen auf den Markt gebracht.\n" +
                        "Der Akkuschrauber ist mit einem 2-Gang-Getriebe für hohe Drehzahl ausgestattet, verfügt über ein Drehmoment von stolzen 140 Nm, welches sich elektronisch in 41 Stufen einstellen lässt und ist mit einem digitalen Display versehen. Der bürstenlose Motor sorgt für eine kompaktere Bauweise, lange Lebensdauer, mehr Leistung und hohe Ausdauer. Die ausgereifte Active Feedback sensing Technologie (AFT) schützt den Anwender, indem es den Motor der Maschine stoppt, sobald diese blockiert ist und damit Herumreißen verhindert. Der Tiefentladeschutz schont den Akku durch zeitgenaue Abschaltung.\n" +
                        "Die Makita XGT®-Serie ermöglicht digitale Kommunikation zwischen  Maschine, Akku und Ladegerät. Somit wird der Akkustand durchgängig überwacht und später wieder komplett aufgeladen.\n" +
                        "Der Akkubohrschrauber im Test: Diese Maschine vereint Leistung und intelligente Technik in einem robusten Gehäuse. Der DF001G eignet sich hervorragend für schwere Arbeiten und höchste Ansprüche."
        },

        {
            endpoint: "makita-xgt-hp001g",
            image_url: "../images/hp001.png",
            title: "Der Akku-Schlagbohrschrauber für höchste Ansprüche",
            script_url_three_d: "//orbitvu.co/share/UucKo3j8mUyvfyHYL5diK4/144569/orbittour/script",
            id: "orbittour-UucKo3j8mUyvfyHYL5diK4",
            description_three_d: [
                {title: "Brushless", description: "für mehr Ausdauer, eine längere Lebensdauer und eine kompaktere Bauweise"},
                {title: "2-Gang-Getriebe", description: " 2-Gang-Planetengetriebe mit Metallzahnrädern"},
                {title: "LED", description: " integriertes LED-Licht zum Ausleuchten der Arbeitsstelle"}
            ],
            Power: [
                {name: 'Stahl', value: '20 mm'},
                {name: 'Mauerwerk', value: '20 mm'},
                {name: 'Holz', value: '50 mm'},
            ],
            couple: {weich: '68 Nm', hart: '140 Nm'},
            weight: {weight_including : "3"},
            level: {Schalldruckpegel: "75"},
            link: "https://www.makita.de/hp001g.html",
            description: "Der HP001G von Makita ist ein sehr kraftvoller und leistungsstarker Akkuschrauber mit Schraub-, Bohr- und Schlagbohrfunktion. Der kompakte bürstenlose Motor verspricht eine lange Lebensdauer bei mehr Leistung und Ausdauer. Das 2-Gang-Getriebe und ein maximaler Drehmoment von 140 Nm machen diese Maschine zu einem echten Kraftpaket für den professionellen Einsatz.\n" +
                "Der Schlagbohrschrauber ist mit 41 Drehmomenteinstellungen und einem digitalen Display technisch stark ausgereift und fein einstellbar. Die moderne Active Feedback sensing Technologie (AFT) stoppt den Motor beim Blockieren des Werkzeugs und verhindert somit das Herumreißen der Maschine. Der Tiefentladeschutz schaltet die Maschine ab, bevor der Akku komplett entladen ist, wodurch die Akkus geschont werden.\n" +
                "\n" +
                "Die Makita XGT®-Serie ermöglicht digitale Kommunikation zwischen  Maschine, Akku und Ladegerät. Somit wird der Akkustand durchgängig überwacht und später wieder komplett aufgeladen.\n" +
                "Fazit nach unserem Test: Der HP001G ist ein 40V Schlagbohrschrauber für den professionellen Einsatz. Hohe Leistung und moderne Technologie wurden hier in einer Maschine vereint."
        }
    ]

    const filterProduct = () => {
       return products.filter(
            (product) => product.endpoint === endpoint
        )[0];
    }

    return (
        <>
            {
                endpoint === "makita-dhp-482"?
                    <>
                       <div className="md:flex text-white m-auto md:w-2/3 py-[10%] px-3">
                           <div className="md:w-1/2">
                               <AnimatedElement classNames="left animated-element animate-right px-5 mt-10" diresction="right" key="87">
                                   <h1 className="lg:text-[55px] text-3xl font-bold">{filterProduct().title_video}</h1>
                                   <h1 className="lg:text-[40px] md:text-[30px] text-2xl mt-5 leading-10">{filterProduct().description_video}</h1>
                               </AnimatedElement>

                           </div>
                           <div className="md:w-1/2">
                               <div
                                   className="e-hosted-video elementor-wrapper elementor-fit-aspect-ratio elementor-open-inline">
                                   <video
                                          src={filterProduct().video_url}
                                          autoPlay loop playsInline muted controlsList="nodownload"></video>
                               </div>
                           </div>
                       </div>
                        <div className="bg-[#121317] md:w-full text-white justify-center justify-items-center items-center m-auto py-16">
                            <div className="container justify-center justify-items-center items-center m-auto md:w-3/4 px-3">
                                <h1 className="md:text-[31px] text-lg text-[#A0A0A0] font-[700]">Performance</h1>
                                <h1 className="lg:text-[51px] md:text-[40px] text-xl font-[700] md:my-5 ">{filterProduct().title}</h1>
                                <p className="text-[#A0A0A0] leading-9 md:mt-0 mt-5">
                                    {filterProduct().description}
                                </p>
                                <p className="text-center md:mt-[100px] mt-[50px]">
                                    <a href={filterProduct().link} className="text-[#39888A] ">mehr erfahren ></a>
                                </p>
                            </div>
                        </div>
                        <section className="section-1 lg:container mx-auto text-white lg:my-[100px] lg:py-[50px] lg:px-[px] relative">
                            <div className="w-full flex flex-col space-y-10 md:flex-row md:space-y-0 justify-between items-center mx-auto md:mt-16 lg:mt-0">
                                {
                                    filterProduct().description_three_d.length?
                                        <div className="md:flex lg:w-4/5 lg:container m-auto lg:space-x-4 text-center justify-center items-center justify-items-center md:mb-0 mb-10">
                                            {filterProduct().description_three_d.map((item, id) => {
                                                return (
                                                    <AnimatedElement classNames="left text-center animated-element animate-right px-5 mt-10" diresction="right" key={id}>
                                                        <h3 className="text-white ld:text-[37px] text-[25px]">{item.title}</h3>
                                                        <p className="text-[#A0A0A0] lg:px-[20%] "> {item.description}</p>
                                                    </AnimatedElement>
                                                );
                                            })}
                                        </div>
                                        :
                                        <div className="md:flex w-4/5 lg:container m-auto md:space-x-4 text-center justify-center items-center justify-items-center md:mb-0 mb-10">
                                            {filterProduct().description_three_d.map((item, id) => {
                                                return (
                                                    <AnimatedElement classNames="left text-center" direction="right" key={id}>
                                                        <h3 className="text-white text-[37px]">{item.title}</h3>
                                                        <p className="text-[#A0A0A0] md:px-10"> {item.description}</p>
                                                    </AnimatedElement>
                                                );
                                            })}
                                        </div>
                                }
                            </div>
                            <div
                                className="e-hosted-video elementor-wrapper elementor-fit-aspect-ratio elementor-open-inline text-center m-auto">
                                <video
                                    className="m-auto mt-16"
                                    src={filterProduct().video_url2}
                                    autoPlay loop playsInline muted controlsList="nodownload"></video>
                            </div>
                        </section>
                        <section className="bg-[#121317]  section-2 w-full">
                            <div className="container mx-auto md:p-[60px] space-y-[50px] text-white lg:my-[150px] lg:w-2/3 md:my-[30px] lg:py-[100px] py-[50px] px-[30px] md:px-[50px] justify-center items-center justify-items-center text-center m-auto">
                                <div className="section-title flex flex-col justify-start space-y-7 text-left ">
                                    <h3 className="text-[#A0A0A0] text-[25px]">Umdrehungen</h3>
                                    <h3 className="lg:text-[45px] md:text-[40px] text-3xl font-bold">Leerlauf</h3>
                                </div>
                                <div className="grid grid-cols-2 mx-auto md:grid-cols-4 gap-5 items-center  text-center">
                                    <div className="gang flex flex-col space-y-7">
                                        <img src="/images/Gang-1.png" alt="Gang-1" className="image w-[60%] h-auto mx-auto"/>
                                        <div className="text text-center text-[#A0A0A0]">
                                            <p> Leerlaufdrehzahl (1. Gang):</p>
                                            <p>0-400 UpM</p>
                                        </div>
                                    </div>
                                    <div className="gang flex flex-col space-y-7">
                                        <img src="/images/Gang-2.png" alt="Gang-2" className="image w-[60%] h-auto mx-auto"/>
                                        <div className="text text-center text-[#A0A0A0]">
                                            <p> Leerlaufdrehzahl (2. Gang):</p>
                                            <p>0-1500 UpM</p>
                                        </div>
                                    </div>
                                    <div className="gang flex flex-col space-y-7">
                                        <img src="/images/Gang-1-Schlag-V4.png" alt="Gang-1-Schlag-V4" className="image w-[60%] h-auto mx-auto"/>
                                        <div className="text text-center text-[#A0A0A0]">
                                            <p> Leerlaufschlagzahl (1. Gang): </p>
                                            <p>0-6000 UpM</p>
                                        </div>
                                    </div>
                                    <div className="gang flex flex-col space-y-7">
                                        <img src="/images/Gang-2-Schlag-V4.png" alt="Gang-2-Schlag-V4" className="image w-[60%] h-auto mx-auto"/>
                                        <div className="text text-center text-[#A0A0A0]">
                                            <p>Leerlaufschlagzahl (2. Gang):</p>
                                            <p>0-22500 UpM</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section-3 text-white relative container m-auto text-center justify-center items*center justify-items-center lg:w-2/3 md:w-4/5 mt-10">
                            <Multifunctional/>
                        </section>
                        {
                            filterProduct().couple?
                                <section className="section-4 bg-[#121317] my-[150px]">
                                    <div className="container mx-auto py-[100px] px-[30px] md:px-[50px]">
                                        <div className="bg-black p-[24px] space-y-11">
                                            <h2 className="font-bold text-white text-[45px]">Drehmoment</h2>
                                            <div className="space-y-[50px]">
                                                <div className="bar space-y-2">
                                                    <div className="w-full rounded-full h-1.5 bg-black">
                                                        <LaunchBar level="36" />
                                                    </div>
                                                    <div className="text text-[#a0a0a0]">
                                                        Drehmoment (weich): {filterProduct().couple.weich}
                                                    </div>
                                                </div>
                                                <div className="bar space-y-2">
                                                    <div className="w-full rounded-full h-1.5 bg-black">
                                                        <LaunchBar level="50" />
                                                    </div>
                                                    <div className="text text-[#a0a0a0]">
                                                        Drehmoment (hart): {filterProduct().couple.hart}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                :
                                null
                        }
                        <section className="section-5 my-[100px] text-white relative">
                            <div className="container mx-auto space-y-5 md:space-y-0 lg:w-2/3 px-[30px] md:px-[50px] flex flex-col md:flex-row md:justify-between">
                                <AnimatedElement classNames="flex flex-col justify-center space-y-11" direction="right">
                                    <h3 className="text-[30px] text-[#a0a0a0] font-bold">Power</h3>
                                    <h2 className="lg:text-[45px] md:text-[30px] font-bold">Bohrleistung</h2>
                                    <div className="bg-[#121317] p-[32px] space-y-4 w-full">
                                        {
                                            filterProduct().Power.map((item, id) => {
                                                return (
                                                    <p key={id}>
                                                        {item.name} : {item.value}
                                                    </p>
                                                );
                                            })
                                        }
                                    </div>
                                </AnimatedElement>
                                <div className="w-full lg:w-1/2 md:w-2/3">
                                    <div
                                        className="e-hosted-video elementor-wrapper elementor-fit-aspect-ratio elementor-open-inline m-auto">
                                        <video
                                            className="m-auto "
                                            src="/videos/DHP482.mp4"
                                            autoPlay loop playsInline muted controlsList="nodownload"></video>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Makita/>
                        <section className="section-6 bg-[#121317]">
                            <div className="container mx-auto lg:py-[100px] py-[50px] px-[30px] md:px-[50px]">
                                <div className="bg-black p-[24px] space-y-11 text-white">
                                    <div className="lg:space-y-11 space-y-5" >
                                        <h2 className="font-light text-[#a0a0a0] text-2xl lg:text-[30px] md:text-[27px]">Gewichtsmerkmale</h2>
                                        <h2 className="font-bold text-white lg:text-[45px] md:text-[35px] text-3xl">Gewicht</h2>
                                    </div>
                                    <div className="lg:px-[50px] flex items-center lg:space-y-11 md:space-y-0 md:space-x-11">
                                        <div className="flex flex-col space-y-7 w-full w-1/2 text-center">
                                            <p>Gewicht ohne Akku</p>
                                            <p className="text-[#39888A] text-[40px] lg:text-[82px] md:text-[60px]" >
                                                <Counter value={filterProduct().weight.battery} unit="Kg"/>
                                            </p>
                                        </div>
                                        <div className="flex flex-col space-y-7 w-full md:w-1/2 text-center">
                                            <p>Gewicht ohne Akku</p>
                                            <p className="text-[#39888A] text-[40px] lg:text-[82px] md:text-[60px]" >
                                                <Counter value={filterProduct().weight.weight_including} unit="Kg"/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Rechts />
                    </>
                    :
                    <>
                        <div className="w-full">
                            <img src={filterProduct().image_url} alt=""/>
                        </div>

                        <div className="bg-[#121317] md:w-full text-white justify-center justify-items-center items-center m-auto py-16">
                            <div className="container justify-center justify-items-center items-center m-auto md:w-3/4 px-3">
                                <h1 className="md:text-[31px] text-lg text-[#A0A0A0] font-[700]">Performance</h1>
                                <h1 className="lg:text-[51px] md:text-[30px] text-xl font-[700] md:my-5 ">{filterProduct().title}</h1>
                                <p className="text-[#A0A0A0] leading-9 md:mt-0 mt-5 ">
                                    {filterProduct().description}
                                </p>
                                <p className="text-center md:mt-[100px] mt-[50px]">
                                    <a href={filterProduct().link} className="text-[#39888A] ">mehr erfahren ></a>
                                </p>
                            </div>
                        </div>
                        <section className="3d-section  my-10 container mx-auto">
                            <div className="md:w-2/3 mx-auto">
                                <ProductThreeD title={filterProduct().threed_title} link={filterProduct().script_url_three_d} id={filterProduct().id} />
                            </div>
                        </section>
                        <section className="section-1 lg:container mx-auto text-white lg:my-[100px] lg:py-[50px] lg:px-[50px] relative">
                            <div className="w-full flex flex-col space-y-10 md:flex-row md:space-y-0 justify-between items-center mx-auto">
                                {
                                    filterProduct().description_three_d.length?
                                        <div className="md:flex lg:w-4/5 lg:container m-auto lg:space-x-4 text-center justify-center items-center justify-items-center md:mb-0 mb-10">
                                            {filterProduct().description_three_d.map((item, id) => {
                                                return (
                                                    <AnimatedElement classNames="left text-center animated-element animate-right px-5 mt-10" diresction="right" key={id}>
                                                        <h3 className="text-white ld:text-[37px] text-[25px]">{item.title}</h3>
                                                        <p className="text-[#A0A0A0] lg:px-10"> {item.description}</p>
                                                    </AnimatedElement>
                                                );
                                            })}
                                        </div>
                                        :
                                        <div className="md:flex w-4/5 lg:container m-auto md:space-x-4 text-center justify-center items-center justify-items-center md:mb-0 mb-10">
                                            {filterProduct().description_three_d.map((item, id) => {
                                                return (
                                                    <AnimatedElement classNames="left text-center" direction="right" key={id}>
                                                        <h3 className="text-white text-[37px]">{item.title}</h3>
                                                        <p className="text-[#A0A0A0] md:px-10"> {item.description}</p>
                                                    </AnimatedElement>
                                                );
                                            })}
                                        </div>
                                }
                            </div>
                        </section>
                        <section className="bg-[#121317]  section-2 w-full">
                            <div className="container mx-auto md:p-[60px] space-y-[50px] text-white lg:my-[150px] lg:w-2/3 md:my-[30px] lg:py-[100px] py-[50px] px-[30px] md:px-[50px] justify-center items-center justify-items-center text-center m-auto">
                                <div className="section-title flex flex-col justify-start space-y-7 text-left ">
                                    <h3 className="text-[#A0A0A0] text-[25px]">Umdrehungen</h3>
                                    <h3 className="lg:text-[45px] md:text-[40px] text-3xl font-bold">Leerlauf</h3>
                                </div>
                                <div className="grid grid-cols-2 mx-auto md:grid-cols-4 gap-5 items-center  text-center">
                                    <div className="gang flex flex-col space-y-7">
                                        <img src="/images/Gang-1.png" alt="Gang-1" className="image w-[60%] h-auto mx-auto"/>
                                        <div className="text text-center text-[#A0A0A0]">
                                            <p> Leerlaufdrehzahl (1. Gang):</p>
                                            <p>0-400 UpM</p>
                                        </div>
                                    </div>
                                    <div className="gang flex flex-col space-y-7">
                                        <img src="/images/Gang-2.png" alt="Gang-2" className="image w-[60%] h-auto mx-auto"/>
                                        <div className="text text-center text-[#A0A0A0]">
                                            <p> Leerlaufdrehzahl (2. Gang):</p>
                                            <p>0-1500 UpM</p>
                                        </div>
                                    </div>
                                    <div className="gang flex flex-col space-y-7">
                                        <img src="/images/Gang-1-Schlag-V4.png" alt="Gang-1-Schlag-V4" className="image w-[60%] h-auto mx-auto"/>
                                        <div className="text text-center text-[#A0A0A0]">
                                            <p> Leerlaufschlagzahl (1. Gang): </p>
                                            <p>0-6000 UpM</p>
                                        </div>
                                    </div>
                                    <div className="gang flex flex-col space-y-7">
                                        <img src="/images/Gang-2-Schlag-V4.png" alt="Gang-2-Schlag-V4" className="image w-[60%] h-auto mx-auto"/>
                                        <div className="text text-center text-[#A0A0A0]">
                                            <p>Leerlaufschlagzahl (2. Gang):</p>
                                            <p>0-22500 UpM</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section-3 text-white relative container m-auto text-center justify-center items*center justify-items-center lg:w-2/3 md:w-4/5 mt-10">
                            <Multifunctional/>
                        </section>
                        {
                            filterProduct().couple?
                                <section className="section-4 bg-[#121317] my-[100px]">
                                    <div className="container mx-auto py-[100px] px-[30px] md:px-[50px]">
                                        <div className="bg-black p-[24px] space-y-11">
                                            <h2 className="font-bold text-white text-[45px]">Drehmoment</h2>
                                            <div className="space-y-[50px]">
                                                <div className="bar space-y-2">
                                                    <div className="w-full rounded-full h-1.5 bg-black">
                                                        <LaunchBar level="36" />
                                                    </div>
                                                    <div className="text text-[#a0a0a0]">
                                                        Drehmoment (weich): {filterProduct().couple.weich}
                                                    </div>
                                                </div>
                                                <div className="bar space-y-2">
                                                    <div className="w-full rounded-full h-1.5 bg-black">
                                                        <LaunchBar level="50" />
                                                    </div>
                                                    <div className="text text-[#a0a0a0]">
                                                        Drehmoment (hart): {filterProduct().couple.hart}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                :
                                null
                        }
                        <section className="section-5 my-[100px] text-white relative">
                            <div className="container mx-auto space-y-11 md:space-y-0  px-[30px] md:px-[50px] flex flex-col md:flex-row md:justify-between justify-center">
                                <AnimatedElement classNames="flex flex-col justify-center space-y-11" direction="right">
                                    <h3 className="text-[30px] text-[#a0a0a0] font-bold">Power</h3>
                                    <h2 className="text-[45px] font-bold">Bohrleistung</h2>
                                </AnimatedElement>
                                <div className="bg-[#121317] p-[32px] space-y-11 w-full md:w-1/3">
                                    {
                                        filterProduct().Power.map((item, id) => {
                                            return (
                                                <p key={id}>
                                                    {item.name} : {item.value}
                                                </p>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </section>
                        <section className="section-6 bg-[#121317]">
                            <div className="container mx-auto lg:py-[100px] py-[50px] px-[30px] md:px-[50px]">
                                <div className="bg-black p-[24px] space-y-11 text-white">
                                    <div className="lg:space-y-11 space-y-5" >
                                        <h2 className="font-light text-[#a0a0a0] text-2xl lg:text-[30px] md:text-[27px]">Gewichtsmerkmale</h2>
                                        <h2 className="font-bold text-white lg:text-[45px] md:text-[35px] text-3xl">Gewicht</h2>
                                    </div>
                                    <div className="lg:px-[50px] flex items-center lg:space-y-11 md:space-y-0 md:space-x-11">
                                        <div className="flex flex-col space-y-7 w-full w-1/2 text-center">
                                            <p>Gewicht ohne Akku</p>
                                            <p className="text-[#39888A] text-[40px] lg:text-[82px] md:text-[60px]" >
                                                <Counter value={filterProduct().weight.battery} unit="Kg"/>
                                            </p>
                                        </div>
                                        <div className="flex flex-col space-y-7 w-full md:w-1/2 text-center">
                                            <p>Gewicht ohne Akku</p>
                                            <p className="text-[#39888A] text-[40px] lg:text-[82px] md:text-[60px]" >
                                                <Counter value={filterProduct().weight.weight_including} unit="Kg"/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section-8 bg-[#121317]">
                            <div className="container mx-auto lg:py-[100px] md:py-[50px] py-10 px-[30px] md:px-[50px]">
                                <div className="bg-black px-[24px] py-[32px] space-y-11 text-white">
                                    <div className="flex space-x-2 items-center">
                                        <div className="">
                                            <h2 className="font-light text-[#a0a0a0] text-[30px]">Lautstärke</h2>
                                            <h2 className="font-bold text-white lg:text-[45px] md:text-[35px] text-[30px]">Schallpegel</h2>
                                        </div>
                                        <img src="/images/Schallwellen.gif" alt="Schallwellen" className="image w-[200px] h-auto mx-auto md:mx-0"/>
                                    </div>
                                    <div className="md:px-[50px] flex items-center md:space-x-11">
                                        <div className="flex flex-col space-y-11 w-full w-1/2 text-center">
                                            <p>
                                                <i aria-hidden="true" className={`fas fa-volume-up fa-3x text-[#39888A]`}></i>
                                            </p>
                                            <p className="text-[#a0a0a0]">Schalldruckpegel</p>
                                            {
                                                filterProduct().level.Schalldruckpegel !== ""?
                                                    <p className="text-white font-bold text-[50px]">
                                                        <Counter value={filterProduct().level.Schalldruckpegel} unit="dB (A)"/>
                                                    </p>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="flex flex-col space-y-11 w-full w-1/2 text-center">
                                            <p>
                                                <i aria-hidden="true" className={`fas fa-volume-up fa-3x text-[#39888A]`}></i>
                                            </p>
                                            <p className="text-[#a0a0a0]">Schallleistungspege</p>
                                            {
                                                filterProduct().level.Schalldruckpegel !== ""?
                                                    <p className="text-white font-bold text-[50px]">
                                                        <Counter value={filterProduct().level.Schallleistungspegel} unit="dB (A)"/>
                                                    </p>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Rechts />
                    </>
            }

            <Footer bg="bg-[#0f0f0f]" />
        </>

    );
};

export default ViewProduct;