import React, {useEffect, useState} from "react";

const Counter = (props) => {

    const {value, unit} = props;

    const [weight, setWeight] = useState(0);

    useEffect(() => {
        setWeight(value)
    }, [value]);

    return (
        <>
            <span className="fe-countable" data-count={weight}>{weight}</span> {unit}
        </>
    );
}

export default Counter;