import React from 'react';
import AnimatedElement from "./AnimatedElement";

const Makita = () => {
    return (
        <section className="md:flex w-full lg:w-2/3 md:w-4/5 m-auto justify-center justify-items-center items-center text-white">
            <AnimatedElement classNames="md:w-1/3 w-full m-auto md:px-0 px-6 md:py-0 py-8" direction="right">
                <h2 className="text-[#A0A0A0] font-[400] md:text-[27px] text-[28px] leading-3">Makita</h2><br/>
                <h1 className="md:text-[35px] lg:text-[47px] text-[30px] font-[500] leading-3 my-2">Ersatzteile gesucht ?</h1><br/>
                <a href="https://toolbrothers.com/" target="_blank" className="text-[#39888A] md:mt-10" rel="noreferrer">Jetzt beraten lassen ></a>
            </AnimatedElement>
            <div className="md:w-2/3 w-full m-auto">
                <img src="../images/DHP482-3Explosion.gif" className="m-auto" alt=""/>
            </div>
        </section>
    );
};

export default Makita;