import React from 'react';
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
const ProductComparison = () => {
        const [scroll, setScroll] = useState(false)
        const [marginImage, setMarginImage] = useState(0)
        let margin = 0;

        useEffect(() => {
            window.addEventListener("scroll", () => {
                const ScrollImages = document.getElementById("scroll-images-container")?.getBoundingClientRect()

                if (ScrollImages && ScrollImages.top < window.innerHeight && ScrollImages.bottom > 92) {
                    setScroll(true)
                    setMarginImage( (window.scrollY / 7) - 150)
                }
            })
        }, [])

    return (
        <>
            <div className={`w-full m-auto container justify-center text-center justify-items-center items-center text-white mt-[10%] mb-[8%]`}>
                <div className="md:flex">
                    <div className="md:w-1/2 w-full m-auto">
                        <a href="https://makita-akkuschrauber.de/category/bohrschrauber/" className="text-[25px] text-semibold mb-font-makita">Bohrschrauber</a>
                        <h5 className="mt-4 mb-font-makita">schnell und effektiv</h5>
                    </div>
                    <div className="md:w-1/2 w-full m-auto">
                        <a href="https://makita-akkuschrauber.de/category/bohrschrauber/" className="text-[25px] text-semibold mb-font-makita">Schlagbohrschrauber</a>
                        <h5 className="mt-4 mb-font-makita">kompakt und vielfältig</h5>
                    </div>
                </div>
                <div className={`md:flex md:mt-10 mt-5 md:mb-10 `} id="scroll-images-container">
                    <div className="md:w-1/3 w-full m-auto">
                        <img src="images/Makita-DDF-Landingpage_2-1-Kopie-792x1024.png" style={{transform: `translate(${marginImage}px, 0px)`}} className={`w-2/5 m-auto`} alt=""/>
                    </div>
                    <div className="md:w-1/3 w-full m-auto">
                        <img src="images/VS-Logo.png" className="md:mt-10 mt-5 md:mb-10 w-1/3 m-auto" alt=""/>
                        <Link to="/unterschied-ddf-und-dhp" className="text-[#39888A] md:mt-10" rel="noreferrer">mehr erfahren ></Link>
                    </div>
                    <div className="md:w-1/3 w-full m-auto">
                        <img src="images/Makita-DDF-Landingpage_2-11-Kopie-768x1056.png" style={{transform: `translate(-${marginImage}px, 0px)`}} className="w-2/5 m-auto" alt=""/>
                    </div>
                </div>

            </div>
        </>

    );
};

export default ProductComparison;