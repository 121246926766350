import React, {useEffect} from 'react';

const ProductThreeD = (props) => {
    const {title, link, id} = props
    useEffect(() => {
        function injectScript(src) {
            let script = document.createElement("script");
            script.type = "application/javascript";
            script.src = src;

            document.body.appendChild(script);
        }

        const exists = document.querySelector(`script[src="${link}"]`);

        if (!exists) {
            injectScript(link);
        }
        else {
            document.body.removeChild(exists);
            injectScript(link);
        }

    }, [props])

    return (
        <div className="mt-[3%] m-auto text-white text-center justify-center items-center mb-[5%] w-full m-auto">
            <a href="https://makita-akkuschrauber.de/category/bohrschrauber/" className="lg:text-[36px] md:text-[25px] text-xl m-auto">
                {title}
            </a>
            <div id={id} className="orbittour-container z-10 mt-5"></div>
        </div>
    );
};

export default ProductThreeD;