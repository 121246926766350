import React from 'react';
import {Link} from "react-router-dom";

const Banners = (props) => {
    const {logo, textOrImage, link} = props
    return (
        <div className="w-full  text-white py-5 mb-font-makita mb-shadow-banner mb-10">
            <div className="container m-auto flex text-center justify-center justify-items-center items-center w-full">
                <div className="md:w-1/4 w-full text-center items-center m-auto justify-center justify-items-center">
                    <img src={logo} className="m-auto md:w-auto w-1/2" alt=""/>
                </div>
                <div className="md:w-2/4 w-full lg:text-[29px] md:text-[20px] text-[8px] font-bold uppercase italic m-auto">{textOrImage}</div>
                <div className="md:w-1/4 w-full justify-between">
                    <Link href={link} className="text-[#39888A] m-auto md:text-auto md:text-[17px]" rel="noreferrer">Jetzt Entdecken ></Link>
                </div>
            </div>
        </div>
    );
};

export default Banners;