import React from 'react';
import {Link} from "react-router-dom";
import Map from "../../Components/Content_components/Map";
import Footer from "../../Components/Footer";

const Agb = () => {
    return (
        <>
            <div className="md:px-0 px-3">
                <div className="text-white m-auto items-center justify-center container md:w-2/3 mb-[3%]">
                    <h1 className="xl:text-[52px] lg:text-[40px] md:text-[30px] text-2xl mt-[8%] font-semibold text-center">Unsere AGB's</h1>
                </div>
                <div className="mb-[2%] md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="font-semibold text-xl">Allgemeine Geschäftsbedingungen und Kundeninformationen</h1>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container">
                    <p >
                        I. Allgemeine Geschäftsbedingungen
                    </p>
                </div>
                <div className="mb-[2%] md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="font-semibold text-xl mt-[3%]">§ 1 Grundlegende Bestimmungen</h1>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container leading-8">
                    (1) Die nachstehenden Geschäftsbedingungen gelten für Verträge, die Sie mit uns als Anbieter (Toolbrother PowerTools GmbH) über die Internetseite <br/><br/>
                    <a href="www.toolbrothers.com" className="text-[#39888A]"> www.toolbrothers.com </a> schließen. Soweit nicht anders vereinbart, wird der Einbeziehung gegebenenfalls von Ihnen verwendeter eigener
                    Bedingungen widersprochen.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    (2) Verbraucher im Sinne der nachstehenden Regelungen ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die
                    überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann. Unternehmer ist jede
                    natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer <br/><br/>
                    selbständigen beruflichen oder gewerblichen Tätigkeit handelt.
                </div>
                <div className="mb-[2%] md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="font-semibold text-xl mt-10">§ 2 Zustandekommen des Vertrages</h1>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container">
                    <p >
                        (1) Gegenstand des Vertrages ist der Verkauf von Waren.
                    </p>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    (2) Bereits mit dem Einstellen des jeweiligen Produkts auf unserer Internetseite
                    unterbreiten wir Ihnen ein verbindliches Angebot zum Abschluss eines
                    Vertrages zu den in der Artikelbeschreibung angegebenen Bedingungen.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    (3) Der Vertrag kommt über das Online-Warenkorbsystem wie folgt zustande:
                    Die zum Kauf beabsichtigten Waren werden im „Warenkorb“ abgelegt. Über
                    die entsprechende Schaltfläche in der Navigationsleiste können Sie den „Warenkorb“
                    aufrufen und dort jederzeit Änderungen vornehmen. Nach Aufrufen der Seite „Kasse“ und der Eingabe
                    der persönlichen Daten sowie der Zahlungs- und Versandbedingungen werden abschließend nochmals alle
                    Bestelldaten auf der Bestellübersichtsseite angezeigt. Soweit Sie als Zahlungsart ein Sofortzahl-System (z.B.
                    PayPal / PayPal Express, Amazon-Payments, Sofort) nutzen, werden Sie entweder in unserem Online-Shop auf die
                    Bestellübersichtsseite geführt oder Sie werden zunächst auf die Internetseite des Anbieters des SofortzahlSystems
                    weitergeleitet. Erfolgt die Weiterleitung zu dem jeweiligen Sofortzahl-System, nehmen Sie dort die entsprechende
                    Auswahl bzw. Eingabe Ihrer Daten vor. Abschließend werden Sie zurück in unseren Online-Shop auf die Bestellübersichtsseite
                    geleitet. Vor Absenden der Bestellung haben Sie die Möglichkeit, hier sämtliche Angaben nochmals zu überprüfen, zu ändern (auch über die Funktion „zurück“ des
                    Internetbrowsers) bzw. den Kauf abzubrechen. Mit dem Absenden der Bestellung über die Schaltfläche
                    „kaufen“ erklären Sie rechtsverbindlich die Annahme des Angebotes, wodurch der Vertrag zustande kommt.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    (4) Ihre Anfragen zur Erstellung eines Angebotes sind für Sie unverbindlich.
                    Wir unterbreiten Ihnen hierzu ein verbindliches Angebot in Textform (z.B. per E-Mail),
                    welches Sie innerhalb von 5 Tagen annehmen können.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    (5) Die Abwicklung der Bestellung und Übermittlung aller im Zusammenhang mit dem
                    Vertragsschluss erforderlichen Informationen erfolgt per E-Mail zum Teil automatisiert. Sie haben deshalb
                    sicherzustellen, dass die von Ihnen bei uns hinterlegte E-Mail-Adresse zutreffend ist, der
                    Empfang der E-Mails technisch sichergestellt und insbesondere nicht durch SPAM-Filter verhindert wird.
                </div>
                <div className="mb-[2%] md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="font-semibold text-xl mt-[3%]">§ 3 Besondere Vereinbarungen zu angebotenen Zahlungsarten</h1>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    (1) Bonitätsprüfung
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    Sofern wir in Vorleistung treten, z.B. bei Zahlung auf Rechnung oder Lastschrift, werden Ihre Daten
                    zur Wahrung unserer berechtigten Interessen zum Zwecke der Bonitätsprüfung auf der Basis mathematisch-statistischer Verfahren
                    an die SCHUFA Holding AG, Komoranweg 5, 65201 Wiesbaden, weitergegeben. Wir behalten uns das Recht vor,
                    Ihnen im Ergebnis der Bonitätsprüfung die Zahlungsart auf Rechnung oder Lastschrift zu verweigern.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    (2) Rechnungskauf über paymorrow
                    Bei Zahlung auf Rechnung (Rechnungskauf über paymorrow)
                    wird die bestellte Ware gemeinsam mit der Rechnung an Sie versandt bzw.
                    ausgeliefert. Der Kaufpreis ist innerhalb von 21 (einundzwanzig) Kalendertagen nach Rechnungsdatum zur Zahlung fällig.
                </div>
                <div className="mb-[2%] md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="font-semibold text-xl mt-[3%]">§ 4 Zurückbehaltungsrecht, Eigentumsvorbehalt</h1>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    (1) Ein Zurückbehaltungsrecht können Sie nur ausüben, soweit es sich um Forderungen aus demselben Vertragsverhältnis handelt.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    (2) Die Ware bleibt bis zur vollständigen Zahlung des Kaufpreises unser Eigentum.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    (3) Sind Sie Unternehmer, gilt ergänzend Folgendes:
                    a) Wir behalten uns das Eigentum an der Ware bis zum vollständigen Ausgleich aller Forderungen aus der laufenden Geschäftsbeziehung vor.
                    Vor Übergang des Eigentums an der Vorbehaltsware ist eine Verpfändung oder Sicherheitsübereignung nicht zulässig. <br/>
                    b) Sie können die Ware im ordentlichen Geschäftsgang weiterverkaufen. Für diesen Fall treten Sie bereits jetzt alle Forderungen in Höhe
                    des Rechnungsbetrages, die Ihnen aus dem Weiterverkauf erwachsen, an uns ab, wir nehmen die Abtretung an. Sie sind weiter zur Einziehung der
                    Forderung ermächtigt. Soweit Sie Ihren Zahlungsverpflichtungen nicht ordnungsgemäß nachkommen, behalten wir uns allerdings vor, die Forderung selbst einzuziehen.
                    c) Bei Verbindung und Vermischung der Vorbehaltsware erwerben wir Miteigentum an der neuen Sache im Verhältnis des Rechnungswertes der Vorbehaltsware
                    zu den anderen verarbeiteten Gegenständen zum Zeitpunkt der Verarbeitung.
                    d) Wir verpflichten uns, die uns zustehenden Sicherheiten auf Ihr Verlangen insoweit freizugeben, als der realisierbare Wert unserer Sicherheiten die
                    zu sichernde Forderung um mehr als 10% übersteigt. Die Auswahl der freizugebenden Sicherheiten obliegt uns.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="font-semibold text-xl">§ 5 Gewährleistung</h1>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    (1) Es bestehen die gesetzlichen Mängelhaftungsrechte.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    (2) Bei gebrauchten Sachen sind die Mängelansprüche ausgeschlossen, wenn sich der
                    Mangel erst nach Ablauf eines Jahres ab Ablieferung der Sache zeigt. Zeigt sich der Mangel innerhalb eines Jahres ab Ablieferung
                    der Sache, können die Mängelansprüche im Rahmen der gesetzlichen Verjährungsfrist
                    von zwei Jahren ab Ablieferung der Sache geltend gemacht werden. Die vorstehende Einschränkung gilt nicht: <br/>
                    <ul className="list-disc m-auto items-center justify-center px-10 leading-8">
                        <li>
                            für uns zurechenbare schuldhaft verursachte Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit und bei
                            vorsätzlich oder grob fahrlässig verursachten sonstigen Schäden;
                        </li>
                        <li>
                            soweit wir den Mangel arglistig verschwiegen oder eine Garantie für die Beschaffenheit der Sache übernommen haben.
                        </li>
                    </ul>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    (3) Als Verbraucher werden Sie gebeten, die Sache bei Lieferung umgehend auf Vollständigkeit, offensichtliche Mängel
                    und Transportschäden zu überprüfen und uns sowie dem Spediteur Beanstandungen schnellstmöglich mitzuteilen.
                    Kommen Sie dem nicht nach, hat dies keine Auswirkung auf Ihre gesetzlichen Gewährleistungsansprüche.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    (4) Soweit Sie Unternehmer sind, gilt abweichend von den vorstehenden Gewährleistungsregelungen:
                    a) Als Beschaffenheit der Sache gelten nur unsere eigenen Angaben und die Produktbeschreibung des Herstellers als vereinbart,
                    nicht jedoch sonstige Werbung, öffentliche Anpreisungen und Äußerungen des Herstellers.
                    b) Bei Mängeln leisten wir nach unserer Wahl Gewähr durch Nachbesserung oder Nachlieferung. Schlägt die Mangelbeseitigung
                    fehl, können Sie nach Ihrer Wahl Minderung verlangen oder vom Vertrag zurücktreten. Die Mängelbeseitigung gilt nach erfolglosem
                    zweiten Versuch als fehlgeschlagen, wenn sich nicht insbesondere aus der Art der Sache oder des Mangels oder den sonstigen Umständen etwas anderes ergibt. Im Falle der Nachbesserung müssen
                    wir nicht die erhöhten Kosten tragen, die durch die Verbringung der Ware an einen anderen Ort als den Erfüllungsort entstehen,
                    sofern die Verbringung nicht dem bestimmungsgemäßen Gebrauch der Ware entspricht. <br/>
                    c) Die Gewährleistungsfrist beträgt ein Jahr ab Ablieferung der Ware. Die Fristverkürzung gilt nicht:
                    <ul className="list-disc m-auto items-center justify-center px-10 leading-8">
                        <li>
                            für uns zurechenbare schuldhaft verursachte Schäden aus der
                            Verletzung des Lebens, des Körpers oder der Gesundheit und bei vorsätzlich oder grob fahrlässig verursachten sonstigen Schäden;
                        </li>
                        <li>
                            soweit wir den Mangel arglistig verschwiegen oder eine Garantie für die Beschaffenheit der Sache übernommen haben;
                        </li>
                        <li>
                            bei Sachen, die entsprechend ihrer üblichen Verwendungsweise für ein Bauwerk verwendet worden sind und dessen Mangelhaftigkeit verursacht haben;
                        </li>
                        <li>
                            bei gesetzlichen Rückgriffsansprüchen, die Sie im Zusammenhang mit Mängelrechten gegen uns haben.
                        </li>
                    </ul>
                </div>
                <div className="mb-[2%] md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="font-semibold text-xl mt-[3%]">§ 6 Rechtswahl, Erfüllungsort, Gerichtsstand</h1>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    (1) Es gilt deutsches Recht. Bei Verbrauchern gilt diese Rechtswahl nur, soweit hierdurch der durch zwingende
                    Bestimmungen des Rechts des Staates des gewöhnlichen Aufenthaltes des Verbrauchers gewährte Schutz nicht entzogen wird (Günstigkeitsprinzip).
                    (2) Erfüllungsort für alle Leistungen aus den mit uns bestehenden Geschäftsbeziehungen sowie Gerichtsstand ist
                    unser Sitz, soweit Sie nicht Verbraucher, sondern Kaufmann, juristische Person des öffentlichen Rechts oder
                    öffentlich-rechtliches Sondervermögen sind. Dasselbe gilt, wenn Sie keinen allgemeinen Gerichtsstand in Deutschland oder der EU haben oder der Wohnsitz oder gewöhnliche
                    Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt ist. Die Befugnis, auch das Gericht an einem anderen gesetzlichen Gerichtsstand anzurufen, bleibt hiervon unberührt.
                    (3) Die Bestimmungen des UN-Kaufrechts finden ausdrücklich keine Anwendung.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="font-semibold text-xl">§ 7 Jugendschutz</h1>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    (1) Beim Verkauf von Ware, die den Regelungen des Jugendschutzgesetzes unterfällt, gehen wir nur Vertragsbeziehungen mit Kunden ein, die das gesetzlich vorgeschriebene Mindestalter
                    erreicht haben. Auf bestehende Altersbeschränkungen wird in der jeweiligen Artikelbeschreibung hingewiesen.
                    (2) Sie versichern mit Absenden Ihrer Bestellung, das gesetzlich vorgeschriebene Mindestalter erreicht zu haben und dass Ihre Angaben bezüglich Ihres Namens und Ihrer Adresse richtig sind.
                    Sie sind verpflichtet, dafür Sorge zu tragen, dass nur Sie selbst oder solche von Ihnen zur Entgegennahme der Lieferung ermächtigte Personen, die das gesetzlich vorgeschriebene
                    Mindestalter erreicht haben, die Ware in Empfang nehmen.
                    (3) Soweit wir aufgrund der gesetzlichen Bestimmungen verpflichtet sind, eine Alterskontrolle vorzunehmen, weisen wir den mit der Lieferung beauftragten Logistikdienstleister an,
                    die Lieferung nur an Personen zu übergeben, die das gesetzlich vorgeschriebene Mindestalter erreicht haben, und sich im Zweifelsfall den Personalausweis der die Ware in Empfang nehmenden Person zur Alterskontrolle vorzeigen zu lassen.
                    (4) Soweit wir über das gesetzlich vorgeschriebene Mindestalter hinausgehend in der jeweiligen Artikelbeschreibung ausweisen, dass Sie für den Kauf der Ware das 18. Lebensjahr
                    vollendet haben müssen, gelten vorstehende Absätze 1-3 mit der Maßgabe, dass anstelle des gesetzlich vorgeschriebenen Mindestalters Volljährigkeit vorliegen muss.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="font-semibold text-xl mt-[3%]">II. Kundeninformationen</h1>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container leading-8">
                    1. Identität des Verkäufers <br/>
                    Toolbrother PowerTools GmbH <br/>
                    Robert Bosch Breite 9 <br/>
                    37079 Goettingen <br/>
                    Deutschland <br/>
                    Telefon: 0551 – 996 90 996 <br/>
                    E-Mail: info@toolbrothers.com

                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    Alternative Streitbeilegung: <br/>
                    Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, aufrufbar unter https://ec.europa.eu/odr.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="font-semibold text-xl mt-[3%]">2. Informationen zum Zustandekommen des Vertrages</h1>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container leading-8">
                    Die technischen Schritte zum Vertragsschluss, der Vertragsschluss selbst und die
                    Korrekturmöglichkeiten erfolgen nach Maßgabe der Regelungen „Zustandekommen des Vertrages“ unserer Allgemeinen Geschäftsbedingungen (Teil I.).
                </div>
                <div className="mb-[2%] md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="font-semibold text-xl mt-[3%]">3. Vertragssprache, Vertragstextspeicherung</h1>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    3.1. Vertragssprache ist deutsch.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    3.2. Der vollständige Vertragstext wird von uns nicht gespeichert. Vor Absenden der Bestellung über das Online
                    – Warenkorbsystem können die Vertragsdaten über die Druckfunktion des Browsers ausgedruckt oder elektronisch gesichert werden. Nach Zugang der
                    Bestellung bei uns werden die Bestelldaten, die gesetzlich vorgeschriebenen Informationen bei Fernabsatzverträgen und die Allgemeinen Geschäftsbedingungen nochmals per E-Mail an Sie übersandt.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    3.3. Bei Angebotsanfragen außerhalb des Online-Warenkorbsystems erhalten Sie alle Vertragsdaten im Rahmen eines verbindlichen
                    Angebotes in Textform übersandt, z.B. per E-Mail, welche Sie ausdrucken oder elektronisch sichern können.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="font-semibold text-xl mt-[3%]">4. Verhaltenskodizes</h1>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container leading-8">
                    4.1. Wir haben uns den Käufersiegel-Qualitätskriterien der Händlerbund Management
                    AG und damit einhergehend dem Ecommerce Europe Trustmark Code of Conduct unterworfen, einsehbar unter:
                    <a href="https://downloads.haendlerbund.de/kaeufersiegel/kaeufersiegelzertifizierungskriterien.pdf" target="_blank" className="text-[#39888A]"> https://www.haendlerbund.de/de… </a> und
                    <a href="https://ecommercetrustmark.eu/the-code-of-conduct/" className="text-[#39888A]" target="_blank"> https://www.ecommercetrustmark… </a>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="font-semibold text-xl mt-[3%]">5. Wesentliche Merkmale der Ware oder Dienstleistung</h1>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container leading-8">
                    Die wesentlichen Merkmale der Ware und/oder Dienstleistung finden sich im jeweiligen Angebot.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="font-semibold text-xl mt-[3%]">6. Preise und Zahlungsmodalitäten</h1>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container leading-8">
                    6.1. Die in den jeweiligen Angeboten angeführten Preise sowie die Versandkosten
                    stellen Gesamtpreise dar. Sie beinhalten alle Preisbestandteile einschließlich aller anfallenden Steuern.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    6.2. Die anfallenden Versandkosten sind nicht im Kaufpreis enthalten. Sie sind über eine entsprechend bezeichnete Schaltfläche auf unserer Internetpräsenz oder im
                    jeweiligen Angebot aufrufbar, werden im Laufe des Bestellvorganges gesondert ausgewiesen und sind von Ihnen zusätzlich zu tragen, soweit nicht die versandkostenfreie Lieferung zugesagt ist.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    6.3. Erfolgt die Lieferung in Länder außerhalb der Europäischen Union können von uns nicht zu vertretende weitere Kosten anfallen,
                    wie z.B. Zölle, Steuern oder Geldübermittlungsgebühren (Überweisungs- oder Wechselkursgebühren der Kreditinstitute), die von Ihnen zu tragen sind.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    6.4. Entstandene Kosten der Geldübermittlung (Überweisungs- oder Wechselkursgebühren der Kreditinstitute) sind von Ihnen in den
                    Fällen zu tragen, in denen die Lieferung in einen EU-Mitgliedsstaat erfolgt, die Zahlung aber außerhalb der Europäischen Union veranlasst wurde.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    6.5. Die Ihnen zur Verfügung stehenden Zahlungsarten sind unter einer entsprechend bezeichneten Schaltfläche auf unserer Internetpräsenz oder im jeweiligen Angebot ausgewiesen.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    6.6. Soweit bei den einzelnen Zahlungsarten nicht anders angegeben, sind die Zahlungsansprüche aus dem geschlossenen Vertrag sofort zur Zahlung fällig.
                </div>
                <div className="mb-[2%] md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="font-semibold text-xl mt-[3%]">7. Lieferbedingungen</h1>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    7.1. Die Lieferbedingungen, der Liefertermin sowie gegebenenfalls bestehende Lieferbeschränkungen
                    finden sich unter einer entsprechend bezeichneten Schaltfläche auf unserer Internetpräsenz oder im jeweiligen Angebot.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container mt-10 leading-8">
                    7.2. Soweit Sie Verbraucher sind ist gesetzlich geregelt, dass die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung
                    der verkauften Sache während der Versendung erst mit der Übergabe der Ware an Sie übergeht, unabhängig davon, ob die Versendung versichert oder unversichert erfolgt. Dies gilt nicht, wenn Sie eigenständig ein
                    nicht vom Unternehmer benanntes Transportunternehmen oder eine sonst zur Ausführung der Versendung bestimmte Person beauftragt haben.
                    Sind Sie Unternehmer, erfolgt die Lieferung und Versendung auf Ihre Gefahr.
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="font-semibold text-xl mt-[3%]">8. Gesetzliches Mängelhaftungsrecht</h1>
                </div>
                <div className="md:w-3/5 text-white m-auto items-center justify-center container leading-8 mb-[5%]">
                    Die Mängelhaftung richtet sich nach der Regelung „Gewährleistung“ in unseren Allgemeinen Geschäftsbedingungen
                    (Teil I). Diese AGB und Kundeninformationen wurden von den auf IT-Recht spezialisierten Juristen des Händlerbundes
                    erstellt und werden permanent auf Rechtskonformität geprüft. Die Händlerbund Management AG garantiert für die Rechtssicherheit der Texte und haftet im Falle von Abmahnungen. Nähere Informationen dazu finden Sie unter:https://www.haendlerbund.de/de/leistungen/rechtssicherheit/agbservice.
                    letzte Aktualisierung: 23.10.2019
                </div>
                <Map />
            </div>
            <Footer bg="bg-black" />
        </>

    );
};

export default Agb;