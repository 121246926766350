import React from 'react';
import ProductThreeD from "../Components/ThreeD/ProductThreeD";
import {Link} from "react-router-dom";
import Map from "../Components/Content_components/Map";
import Rechts from "../Components/Rechts";
import AnimatedElement from "../Components/AnimatedElement";
import Footer from "../Components/Footer";
const DdfVsDhp = () => {
    return (
        <div className="">
            <div className="text-white m-auto items-center justify-center container md:w-2/3 md:px-0 px-3 relative">
                <AnimatedElement type="bounce">
                    <h1 className="xl:text-[52px] lg:text-[40px] md:text-[30px] text-3xl mt-[8%] font-semibold text-center">DDF VS DHP</h1>
                </AnimatedElement>
                <img src="../images/Unbenannt-1-300x300.png" className="md:hidden block mt-10 m-auto"alt=""/>
            </div>
            <section className="bg-[#121317] py-[5%] mt-[5%] md:px-0 px-3">
                <div className="md:w-3/5 text-white m-auto items-center justify-center container relative">
                    <AnimatedElement type="bounce">
                        <h1 className="xl:text-[52px] lg:text-[40px] md:text-[30px] text-2xl font-semibold my-10">Der Unterschied zwischen DDF und DHP</h1>
                        <p className=" leading-8 text-[#A0A0A0]">
                            Der entscheidende Unterschied der beiden Modellreihen ist die Schlagfunktion. Der DDF ist ein Akku-Bohrschrauber
                            und der DHP ein Akku-Schlagbohrschrauber. Der DDF ist damit zum Schrauben und Bohren in herkömmliche Materialien gedacht wogegen der
                            DHP selbst vor Ziegel, Stein oder Beton nicht halt macht. Wenn die zwei Geräte in Klassen eingeteilt werden müssten, wäre der DDF
                            so etwas wie der Heimwerker König, da er eigentlich in jeder Werkstatt zu finden ist und der DHP sowas wie sein großer starker Bruder,
                            der ausgepackt wird, wenn einmal richtig Power benötigt wird. Wenn’s um Präzision beim Bohren und Schrauben geht ist der DDF die richtige Wahl, wenn es um Durchschlagskraft
                            geht der DHP. Mit Extra Power kommen auch kleine Nachteile mit sich. Die Schlagfunktion macht das feine Bohren ungenauer, den Bohrer schwerer und außerdem ist das Schlagwerk ein weiteres Verschleißteil.
                        </p>
                    </AnimatedElement>
                </div>
            </section>
            <section className="3d-section container mx-auto">
                <div className="md:w-2/3 mx-auto">
                    <ProductThreeD title="DDF - Der Akku Bohrschrauber" link="//orbitvu.co/share/cc9bzrGnLomcdHwYECax6V/144569/orbittour/script" id="orbittour-cc9bzrGnLomcdHwYECax6V" />
                </div>
            </section>
            <section className="md:px-0 px-3">
                <div className="md:w-3/5 text-white m-auto items-center justify-center container">
                    <p className=" leading-8 text-[#A0A0A0]">
                        <Link to="/bohrschrauber"  className="text-[#39888A] font-bold"> DDF </Link> steht bei Makita für <Link to="/bohrschrauber"  className="text-[#39888A]"> Akku-Bohrschrauber </Link>. Das D steht für die Kompatibilität mit dem 18V Akku-System von Makita, DF dafür das es ein Bohrschrauber ist. <br/>
                        Der <Link to="/bohrschrauber"  className="text-[#39888A]"> Akku–Bohrschrauber </Link> erweitert den normalen Akkuschrauber um die Bohrfunktion, er ist also zum Bohren und Schrauben gedacht. Das Schnellspannbohrfutter ermöglicht es Bit- und Bohraufsätze zu nutzen.
                        Der Bohrschrauber eignet sich am besten für die Bearbeitung von Holz, Metall, Keramik und Kunststoff. <br/>
                        Um die Leistung der Aufgaben oder dem Material anzupassen und stets mit der richtigen Intensität zu arbeiten, hat der DDF
                        zwei Gänge, einen langsameren für Schraubarbeiten und einen schnelleren zum Bohren. Für Schraubarbeiten kann das Drehmoment häufig zusätzlich nochmal geregelt werden. <br/>

                        Modelle: DDF343, DDF446, DDF448, DDF451, DDF453, DDF456, DDF458, DDF459, DDF480, DDF483, DDF484, DDF485
                    </p>
                </div>
                <div className="text-[#39888A] text-center mt-16">
                    <a href="https://toolbrothers.com/?match=all&subcats=Y&pcode_from_q=Y&pshort=Y&pfull=Y&pname=Y&pkeywords=Y&search_performed=Y&q=makita+Bohrschrauber&dispatch=products.search&security_hash=99f607f7f1ce73f4cf6e0a41f718f6f1"> Jetzt Makita Bohrschrauber entdecken > </a>
                </div>
            </section>
            <section className="3d-section container mx-auto">
                <div className="md:w-2/3 mx-auto">
                    <ProductThreeD title="DHP - Der Akku Schlagbohrschrauber" link="//orbitvu.co/share/NFUm6ZMeKnk5QnR4uer7f4/144569/orbittour/script" id="orbittour-NFUm6ZMeKnk5QnR4uer7f4" />
                </div>
            </section>
            <section className="md:px-0 px-3">
                <div className="md:w-3/5 text-white m-auto items-center justify-center container">
                    <p className=" leading-8 text-[#A0A0A0]">
                        <Link to="/schlagbohrschrauber"  className="text-[#39888A] font-bold"> DHP </Link> steht bei Makita für <Link to="/schlagbohrschrauber"  className="text-[#39888A]"> Akku-Schlagbohrschrauber </Link>, also Bohrschrauber mit zusätzlicher Schlagfunktion. Das D steht für die Kompatibilität mit dem 18V Akku-System von Makita, HP dafür das es ein Schlagbohrschrauber ist. <br/>
                        Der DHP ist sowas wie der universell-einsetzbare Allrounder unter den Schraubern und vereint Schrauben, Bohren und die Schlagfunktion in einem Gerät. Er eignet sich besonders gut für schwere Bohr- und Schraubarbeiten in fast allen Materialen wie Ziegel, Stein, Holz, Metall, Beton, je nach Kraft des einzelnen Modells. <br/>
                        Wie der DDF verfügt auch der <Link to="/schlagbohrschrauber"  className="text-[#39888A]"> DHP </Link> über zwei Gänge, einen zum Bohren und einen zum Schrauben, nur hat er zusätzlich noch eine Schlagfunktion als einstellbare Stufe. <br/>
                        Die Schlagfunktion ist axial ausgerichtet, wodurch die Kraft nach vorne gerichtet wird umso noch besser durch härtere Materialien bohren zu können.Dadurch wird der <Link to="/schlagbohrschrauber"  className="text-[#39888A]"> DHP </Link> natürlich nochmal etwas schwerer und länger als sein bohrender Verwandter. Das Schlagwerk sorgt für einen unsaubereren Rundlauf bei feinen Bohrarbeiten in beispielsweise Metall, wodurch diese Bohrarbeiten ungenauer ausfallen. Außerdem ist es ein weiteres Verschleißteil. Die zusätzliche Schlagfunktion lohnt sich also nur, wenn sie auch benötigt wird.

                        Modelle: DHP446, DHP451, DHP453, DHP456, DHP458, DHP459, DHP480, DHP481, DHP482, DHP483, DHP484, DHP485
                    </p>
                </div>
                <div className="text-[#39888A] text-center mt-16">
                    <a href="https://toolbrothers.com/?match=all&subcats=Y&pcode_from_q=Y&pshort=Y&pfull=Y&pname=Y&pkeywords=Y&search_performed=Y&q=makita+Schlagbohrschrauber&dispatch=products.search&security_hash=99f607f7f1ce73f4cf6e0a41f718f6f1"> Jetzt Makita Schlagbohrschrauber entdecken > </a>
                </div>
            </section>
            <section className="text-white m-auto items-center justify-center container md:w-3/5 md:px-0 px-3">
                <h1 className="xl:text-[40px] lg:text-[40px] md:text-[30px] text-2xl mt-[8%] font-semibold">REIS-LEISTUNGS-SIEGER ?! MAKITA DHP 482</h1>
            </section>

            <section className="m-auto text-center items-center justify-center justify-items-center my-10 md:px-0 px-3" data-id="ca0fda9" data-element_type="widget" data-settings="{&quot;youtube_url&quot;:&quot;https:\/\/www.youtube.com\/watch?v=HRhmnoInAK0&amp;t=9s&quot;,&quot;video_type&quot;:&quot;youtube&quot;,&quot;controls&quot;:&quot;yes&quot;,&quot;aspect_ratio&quot;:&quot;169&quot;}" data-widget_type="video.default">
                <div className="m-auto elementor-widget-container">
                    <div className="m-auto elementor-wrapper elementor-fit-aspect-ratio elementor-open-inline">
                        <iframe className="m-auto elementor-video md:w-[70%] lg:w-[60%] xl:w-[70% w-full md:h-[590px] h-[200px]"  frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="PREIS-LEISTUNGS-SIEGER ?! 👍 Der MAKITA DHP 482 18 Volt Akku-Schlagbohrschrauber" src="https://www.youtube.com/embed/HRhmnoInAK0?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fmakita-akkuschrauber.de&amp;widgetid=1" id="widget2"></iframe>		</div>
                </div>
            </section>
            <section className=" mt-[5%] md:px-0 px-3">
                <div className="lg:w-3/5 md:w-4/5 text-white m-auto items-center justify-center container">
                    <div className="md:flex justify-center items-center justify-items-center">
                        <div className="">
                            <h1 className="xl:text-[40px] lg:text-[40px] md:text-[30px] text-2xl font-semibold my-10">Der Unterschied zwischen DDF und DHP</h1>
                            <p className=" leading-8 text-[#A0A0A0]">
                                Die Schlagfunktion bei Akku-Schlagbohrschraubern ist axial ausgerichtet, wodurch die Kraft
                                nach vorne gerichtet wird, umso noch besser durch härtere Materialien bohren zu können. Beim Bohren wird
                                so eine Schlagbewegung ausgeführt, die das harte Material, wie
                                beispielsweise Ziegel „weich klopft“. Dadurch kann selbst in festen und harten Materialien ein relativ
                                zügiger Arbeitsfortschritt erzeugt werden, wo beim normalen Bohrschrauber schon lange Schluss gewesen wäre.
                            </p>
                        </div>
                        <div className="">
                            <img src="images/SchlagbohrenV2-1.gif" className="w-[50%] lg:w-[1300px] md:w-[1300px] m-auto" alt=""/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-[#121317] py-[3%] mt-[2%] md:px-0 px-3">
                <div className="md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="xl:text-[40px] lg:text-[40px] md:text-[30px] text-2xl font-semibold my-10 ">DDF, DHP, doch da gibts noch mehr: Der Blog über </h1>
                    <h1 className="xl:text-[40px] lg:text-[40px] md:text-[30px] text-2xl font-semibold my-10 ">Makita Set-Bezeichnungen</h1>
                    <p className=" leading-8 text-[#A0A0A0]">
                        DHP und DDF, das wäre jetzt geklärt. Doch hier hört der Buchstabenwald bei Makita noch lange nicht auf.
                        Doch wer soll bei den Bezeichnungen noch den Durchblick behalten? Besonders wenn Makita hier selber kaum bis gar
                        keine Abhilfe schafft. Um dir Fragen wie: „Wofür stehen die Buchstaben in den
                        Sets bei Makita?“ oder „Was bedeuten die Setbezeichnungen an sich?“ zu beantworten, haben wir eine umfangreiche Auflistung & Erklärung zu den einzelnen Set-Kürzeln erstellt und in einem kurzen Blog gebündelt.
                    </p>
                </div>
                <div className="text-[#39888A] text-center mt-16">
                    <a href="https://toolbrothers.com/blog-toolbrothers/wissenswertes/makita-set-bezeichnungen-und-ihre-bedeutung-tb-verschafft-den-durchblick/" target="_blank"> Zum Blog > </a>
                </div>
            </section>
            <section className="py-[3%] mt-[2%] md:px-0 px-3">
                <div className="md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="xl:text-[40px] lg:text-[40px] md:text-[30px] text-2xl font-semibold my-10 ">Antworten auf Fragen, noch bevor du sie hast: TB-Blogs</h1>
                    <p className=" leading-8 text-[#A0A0A0]">
                        DHP und DDF, das wäre jetzt geklärt. Doch hier hört der Buchstabenwald bei Makita noch lange nicht auf.
                        Doch wer soll bei den Bezeichnungen noch den Durchblick behalten? Besonders wenn Makita hier selber kaum
                        bis gar keine Abhilfe schafft. Um dir Fragen wie: „Wofür stehen die Buchstaben in den Sets bei Makita?“
                        oder „Was bedeuten die Setbezeichnungen an sich?“ zu beantworten, haben wir eine umfangreiche Auflistung
                        & Erklärung zu den einzelnen Set-Kürzeln erstellt und in einem kurzen Blog gebündelt.
                    </p>
                </div>
                <div className="text-[#39888A] text-center mt-16">
                    <a href="https://toolbrothers.com/blog-toolbrothers/" target="_blank"> Toolbrothers Blogs > </a>
                </div>
            </section>

            <section className="bg-[#121317]  py-[3%] mt-[2%] md:px-0 px-3">
                <div className="md:w-3/5 text-white m-auto items-center justify-center container">
                    <h1 className="xl:text-[40px] lg:text-[40px] md:text-[30px] text-2xl font-semibold my-10 ">Entdecken und Vergleichen - Alles im TB-Vergleichsportal</h1>
                    <p className=" leading-8 text-[#A0A0A0]">
                        DHP und DDF, das wäre jetzt geklärt. Doch hier hört der Buchstabenwald bei Makita noch lange nicht auf.
                        Doch wer soll bei den Bezeichnungen noch den Durchblick behalten? Besonders wenn Makita hier selber kaum
                        bis gar keine Abhilfe schafft. Um dir Fragen wie: „Wofür stehen die Buchstaben in den Sets bei Makita?“
                        oder „Was bedeuten die Setbezeichnungen an sich?“ zu beantworten, haben wir eine umfangreiche Auflistung
                        & Erklärung zu den einzelnen Set-Kürzeln erstellt und in einem kurzen Blog gebündelt.
                    </p>
                </div>
                <div className="text-[#39888A] text-center mt-16">
                    <a href="https://toolbrothers.com/" target="_blank"> Zum Toolbrothers Vergleichsportal > </a>
                </div>
            </section>
            <Rechts />
            <Map />
            <Footer bg="bg-[#0f0f0f]" />
        </div>
    );
};

export default DdfVsDhp;