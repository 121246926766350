import React, {useEffect, useState} from 'react';
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, Move, MoveIn, MoveOut, Sticky, StickyIn, StickyOut, Zoom, ZoomIn, ZoomOut } from "react-scroll-motion";



const MakitaAkkuschrauber = () => {
    return (
        <div className="top-0 overflow-hidden">
            <div className="flex md:h-screen w-full items-center justify-center bg-gray-900 bg-cover bg-no-repeat mb-background-image md:mt-0 mt-5 relative">
                <div className="md:w-2/3 z-20 w-full md:mx-0 mx-3 rounded-md bg-black bg-opacity-50 px-16 md:py-10 shadow-lg mb-shadow-makita max-sm:px-8">
                    <div className="text-white text-center">
                        <div className="mb-8 flex flex-col items-center mb-font-makita">
                            <h1 className="mb-2 md:text-[33px] mt-5 text-lg font-bold">Makita-Akkuschrauber.de</h1>
                            <h3 className="md:text-[30px] text-sm md:mt-10 font-bold-[500]">Langliebig und nützlich.</h3>
                            <h3 className="mb-2 md:text-[30px] text-sm md:mt-5 font-bold-[500]">Leistungsstark und zuverlässig.</h3>
                            <span className="text-[#D1D1D1] mt-3 text-[12px] ">Jetzt Elektrowerkzeuge bei Toolbrothers vergleichen</span>
                            <a href="https://toolbrothers.com/" className="text-[#39888A] mt-5" target="_blank" rel="noreferrer">Jetzt Vergleichen ></a>
                        </div>
                    </div>
                </div>
                <div className="mb-overlay"></div>
            </div>
        </div>
    );
};

export default MakitaAkkuschrauber;