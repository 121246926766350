import React from 'react';
import AnimatedElement from "./AnimatedElement";

const Multifunctional = () => {
    return (
        <div className="lg:container mx-auto w-full px-[30px] md:px-[10px] lg:px-[10%] flex flex-col md:flex-row md:justify-between items-center">
                <AnimatedElement classNames="w-full md:w-1/3 flex flex-col space-y-3 items-center md:items-start" direction="right">
                    <h3 className="text-[#A0A0A0] text-[25px]">Multifunktionsfähig</h3>
                    <h2 className="text-[40px]">2-in-1</h2>
                    <p className="text-[#39888A] text-[18px]">
                        <a href="https://toolbrothers.com/?match=all&subcats=Y&pcode_from_q=Y&pshort=Y&pfull=Y&pname=Y&pkeywords=Y&search_performed=Y&q=makita+schlagbohrschrauber&dispatch=products.search&security_hash=2ffb274d61a32a062d84228bc575e949">weitere Makita Schlagbohrschrauber ></a>
                    </p>
                </AnimatedElement>
                <div className="w-full md:w-2/3 flex md:justify-between items-center mt-10 md:mt-0">
                    <div className="w-1/2 text-center">
                        <img src="/images/BohrerV2.gif" alt="BohrerV2" className="image w-[80%] m-auto"/>
                        <p className="lg:text-[40px] md:text-[30px]">Bohren</p>
                    </div>
                    <div className="w-1/2 text-center">
                        <img src="/images/SchlagbohrenV2-1.gif" alt="BohrerV2" className="image w-[80%] m-auto"/>
                        <p className="lg:text-[40px] md:text-[30px]">Schlagbohren</p>
                    </div>
                </div>
            </div>
    );
};

export default Multifunctional;