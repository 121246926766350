import React from 'react';

const Rechts = () => {
    return (
        <section className="section-7 text-white">
            <div className="container m-auto hidden md:block text-white lg:my-[100px] md:my-[70px] my-[70px] px-[50px]">
                <div className="descriptions flex justify-between items-center text-center">
                    <div className="prop space-y-7 w-1/4 p-2">
                        <i className="fas fa-arrows-alt-h text-[55px] font-bold"></i>
                        <h3 className="text-[20px] font-light text-[#a0a0a0]">Rechts-Links Umschaltung</h3>
                    </div>
                    <div className="prop space-y-7 w-1/4 p-2">
                        <i aria-hidden="true" className="fas fa-battery-full text-[55px] font-bold"></i>
                        <h3 className="text-[20px] font-light text-[#a0a0a0]">Rechts-Links Umschaltung</h3>
                    </div>
                    <div className="prop space-y-7 w-1/4 p-2">
                        <i aria-hidden="true" className="fas fa-business-time text-[55px] font-bold"></i>
                        <h3 className="text-[20px] font-light text-[#a0a0a0]">Rechts-Links Umschaltung</h3>
                    </div>
                    <div className="prop space-y-7 w-1/4 p-2">
                        <i aria-hidden="true" className="fab fa-buffer text-[55px] font-bold"></i>
                        <h3 className="text-[20px] font-light text-[#a0a0a0]">Rechts-Links Umschaltung</h3>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Rechts;