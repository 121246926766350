import React from 'react';
import {Link} from "react-router-dom";

const Footer = (props) => {
    let {bg} = props
    return (
        <div className={`text-white py-10 px-3 mt-3 ${bg}`}>
            <div className="container md:flex m-auto  w-full lg:w-4/5 items-center justify-center justify-items-center">
                <div className="md:w-1/3 w-full m-auto md:border-l-2 md:border-[#39888A] md:rounded-l-sm md:h-[250px] px-3 pb-5">
                    <a href="https://www.makita-akkuschrauber.de/?_ga=2.222199775.637286545.1676882383-6095520.1676449160" className="underline font-semibold">www.makita-akkuschrauber.de</a>
                    <div className="mt-5 text-[16px] m-auto">
                        <Link to="/uber-uns" className="text-[#39888A] m-auto">Über uns </Link>
                        <div className="mt-1"><Link to="/kontakt" className="text-[#39888A] m-auto">Kontakt</Link></div>
                        <div className="mt-1"><Link to="/agb" className="text-[#39888A] m-auto">AGB</Link></div>
                        <div className="mt-1"><Link to="/datenschutz" className="text-[#39888A] m-auto">Datenschutz</Link></div>
                        <div className="mt-1"><Link to="impressum-2" className="text-[#39888A] m-auto">Impressum </Link></div>
                    </div>
                </div>
                <div className="md:w-1/3 w-full m-auto md:border-l-2 md:border-[#39888A] md:rounded-l-sm md:h-[250px] px-3 pb-5">
                    <h3 className="underline font-semibold">Adresse</h3>
                    <div className="mt-5 text-[#7a7a7a] text-[16px]">
                        <strong>
                            TOOLBROTHERS POWERTOOLS GMBH
                        </strong>  <br/>
                        <div className="mt-2"><span className="mt-5 m-auto">Robert-Bosch-Breite 9</span></div>
                        <div className="mt-2"><span className="mt-5 m-auto">37079 Göttingen</span></div>
                    </div>
                </div>
                <div className="md:w-1/3 w-full m-auto md:border-l-2 md:border-[#39888A] md:rounded-l-sm md:h-[250px] px-3 pb-5">
                    <h3 className="underline font-semibold">Kontakt</h3>
                    <div className="mt-5 text-[16px] text-[#7a7a7a]" >
                        <div className="mt-2">
                            <span className="m-auto">Telefon: 0551 / 99690988</span>
                            <div className="mt-2 m-auto">E-Mail: </div>
                            <a href="mailto:service@toolbrothers.de pb-10" className="text-[#39888A] m-auto md:text-auto text-[14px] md:text-[17px] m-auto">service@toolbrothers.de</a>
                        </div>
                    </div>
                    <div className="text-center justify-center items-center justify-items-center m-auto flex mt-10 space-x-4 ">
                        <div className="">
                            <a href="https://www.facebook.com/toolbrothers/">
                                <i className="fa-brands fa-facebook-f px-5 py-4 bg-[#3b5998] rounded-full"></i>
                            </a>
                        </div>
                        <div className="">
                            <a href="https://www.youtube.com/channel/UC5XoertU7g5B9BGMGESkgvw">
                                <i className="fa-brands fa-youtube p-4 bg-[#cd201f] rounded-full"></i>
                            </a>
                        </div>
                        <div className="">
                            <a href="https://www.instagram.com/toolbrothers_com/?hl=de">
                                <i className="fa-brands fa-instagram p-4 bg-[#9301e0] rounded-full"></i>
                            </a>
                        </div>
                        <div className="">
                            <a href="https://www.pinterest.de/toolbrothers/" className="">
                                <i className="fa-brands fa-pinterest p-4 bg-[#bd081c] rounded-full text-bold"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;